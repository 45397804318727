import { Injectable, Injector, inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { RequiredHeader } from '../interfaces/auth.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private injector = inject(Injector);

  private authService!: AuthService;
  private translateService!: TranslateService;

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.translateService = this.injector.get(TranslateService);
    const currentLang = this.translateService.currentLang ?? 'de';

    const header: RequiredHeader = {
      'Accept-Language': currentLang,
    };

    this.authService = this.injector.get(AuthService);

    const tenantKey = this.authService.getTenantKey();
    if (
      tenantKey !== null &&
      !request.url.includes('/signup') &&
      !request.url.includes('geo.openresearch.com')
    ) {
      header['X-Tenant-Key'] = tenantKey;
    }

    if (
      !request.url.includes('/auth') &&
      !request.url.includes('/tenants/config') &&
      !request.url.includes('/signup')
    ) {
      const accessToken = this.authService.getAccessToken();
      header.Authorization = `Bearer ${accessToken}`;
    }

    request = request.clone({
      setHeaders: header as any,
    });

    return next.handle(request);
  }
}
