export abstract class EnvHelper {
  public static getWebEnv(): string {
    let activeEnvironment = '';
    const url = window.location.host;
    const shortCode = url.split('.')[0].split(':')[0];
    const possibleEnvironments = ['dev', 'int', 'staging'];
    const foundEnvironments = possibleEnvironments.filter(
      (e) => shortCode === e || url.includes(`.${e}.`)
    );

    if (foundEnvironments.length > 0) {
      activeEnvironment = foundEnvironments[0];
    }
    return activeEnvironment ?? 'production'
  }
}
