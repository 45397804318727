export enum Status {
  /**
   * The last opreation was successfull
   */

  Success = 0,
  /**
   * The last opreation failed
   */
  Failed = 1,

  /**
   * An operation is in progress
   */
  Pending = 2,

  /**
   * State is in default initial state
   * NOTE: this is update manually so doesnt apply to all states
   */
  Default = 3,
}
