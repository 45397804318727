/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { GeoJsonIsochronesResponse } from '../models/geo-json-isochrones-response';
import { getDefaultIsochronesUsingPost } from '../fn/isochrones/get-default-isochrones-using-post';
import { GetDefaultIsochronesUsingPost$Params } from '../fn/isochrones/get-default-isochrones-using-post';


/**
 * Obtain areas of reachability from given locations
 */
@Injectable({ providedIn: 'root' })
export class IsochronesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDefaultIsochronesUsingPost()` */
  static readonly GetDefaultIsochronesUsingPostPath = '/v2/isochrones/{profile}';

  /**
   * Isochrones Service.
   *
   * The Isochrone Service supports time and distance analyses for one single or multiple locations.
   * You may also specify the isochrone interval or provide multiple exact isochrone range values.
   * This service allows the same range of profile options as the /directions endpoint,
   * which help you to further customize your request to obtain a more detailed reachability area response.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDefaultIsochronesUsingPost()` instead.
   *
   * This method sends `application/geo+json` and handles request body of type `application/geo+json`.
   */
  getDefaultIsochronesUsingPost$Response(params: GetDefaultIsochronesUsingPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GeoJsonIsochronesResponse>> {
    return getDefaultIsochronesUsingPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Isochrones Service.
   *
   * The Isochrone Service supports time and distance analyses for one single or multiple locations.
   * You may also specify the isochrone interval or provide multiple exact isochrone range values.
   * This service allows the same range of profile options as the /directions endpoint,
   * which help you to further customize your request to obtain a more detailed reachability area response.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDefaultIsochronesUsingPost$Response()` instead.
   *
   * This method sends `application/geo+json` and handles request body of type `application/geo+json`.
   */
  getDefaultIsochronesUsingPost(params: GetDefaultIsochronesUsingPost$Params, context?: HttpContext): Observable<GeoJsonIsochronesResponse> {
    return this.getDefaultIsochronesUsingPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GeoJsonIsochronesResponse>): GeoJsonIsochronesResponse => r.body)
    );
  }

}
