/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { GeoJsonRouteResponse } from '../models/geo-json-route-response';
import { getDefaultUsingPost1$Any } from '../fn/directions/get-default-using-post-1-any';
import { GetDefaultUsingPost1$Any$Params } from '../fn/directions/get-default-using-post-1-any';
import { getDefaultUsingPost1$Json } from '../fn/directions/get-default-using-post-1-json';
import { GetDefaultUsingPost1$Json$Params } from '../fn/directions/get-default-using-post-1-json';
import { getGeoJsonRouteUsingPost$Json } from '../fn/directions/get-geo-json-route-using-post-json';
import { GetGeoJsonRouteUsingPost$Json$Params } from '../fn/directions/get-geo-json-route-using-post-json';
import { getGeoJsonRouteUsingPost$JsonCharsetUtf8 } from '../fn/directions/get-geo-json-route-using-post-json-charset-utf-8';
import { GetGeoJsonRouteUsingPost$JsonCharsetUtf8$Params } from '../fn/directions/get-geo-json-route-using-post-json-charset-utf-8';
import { getGpxRouteUsingPost$Xml } from '../fn/directions/get-gpx-route-using-post-xml';
import { GetGpxRouteUsingPost$Xml$Params } from '../fn/directions/get-gpx-route-using-post-xml';
import { getGpxRouteUsingPost$XmlCharsetUtf8 } from '../fn/directions/get-gpx-route-using-post-xml-charset-utf-8';
import { GetGpxRouteUsingPost$XmlCharsetUtf8$Params } from '../fn/directions/get-gpx-route-using-post-xml-charset-utf-8';
import { getJsonRouteUsingPost$Json } from '../fn/directions/get-json-route-using-post-json';
import { GetJsonRouteUsingPost$Json$Params } from '../fn/directions/get-json-route-using-post-json';
import { getJsonRouteUsingPost$JsonCharsetUtf8 } from '../fn/directions/get-json-route-using-post-json-charset-utf-8';
import { GetJsonRouteUsingPost$JsonCharsetUtf8$Params } from '../fn/directions/get-json-route-using-post-json-charset-utf-8';
import { getSimpleGeoJsonRouteUsingGet } from '../fn/directions/get-simple-geo-json-route-using-get';
import { GetSimpleGeoJsonRouteUsingGet$Params } from '../fn/directions/get-simple-geo-json-route-using-get';
import { Gpx } from '../models/gpx';
import { JsonRouteResponse } from '../models/json-route-response';


/**
 * Get directions for different modes of transport
 */
@Injectable({ providedIn: 'root' })
export class DirectionsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSimpleGeoJsonRouteUsingGet()` */
  static readonly GetSimpleGeoJsonRouteUsingGetPath = '/v2/directions/{profile}';

  /**
   * Directions Service (GET).
   *
   * Get a basic route between two points with the profile provided. Returned response is in GeoJSON format. This method does not accept any request body or parameters other than profile, start coordinate, and end coordinate.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSimpleGeoJsonRouteUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimpleGeoJsonRouteUsingGet$Response(params: GetSimpleGeoJsonRouteUsingGet$Params, context?: HttpContext): Observable<StrictHttpResponse<GeoJsonRouteResponse>> {
    return getSimpleGeoJsonRouteUsingGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Directions Service (GET).
   *
   * Get a basic route between two points with the profile provided. Returned response is in GeoJSON format. This method does not accept any request body or parameters other than profile, start coordinate, and end coordinate.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSimpleGeoJsonRouteUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimpleGeoJsonRouteUsingGet(params: GetSimpleGeoJsonRouteUsingGet$Params, context?: HttpContext): Observable<GeoJsonRouteResponse> {
    return this.getSimpleGeoJsonRouteUsingGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<GeoJsonRouteResponse>): GeoJsonRouteResponse => r.body)
    );
  }

  /** Path part for operation `getDefaultUsingPost1()` */
  static readonly GetDefaultUsingPost1Path = '/v2/directions/{profile}';

  /**
   * Directions Service (POST).
   *
   * Returns a route between two or more locations for a selected profile and its settings as JSON
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDefaultUsingPost1$Any()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDefaultUsingPost1$Any$Response(params: GetDefaultUsingPost1$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<JsonRouteResponse>> {
    return getDefaultUsingPost1$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Directions Service (POST).
   *
   * Returns a route between two or more locations for a selected profile and its settings as JSON
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDefaultUsingPost1$Any$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDefaultUsingPost1$Any(params: GetDefaultUsingPost1$Any$Params, context?: HttpContext): Observable<JsonRouteResponse> {
    return this.getDefaultUsingPost1$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<JsonRouteResponse>): JsonRouteResponse => r.body)
    );
  }

  /**
   * Directions Service (POST).
   *
   * Returns a route between two or more locations for a selected profile and its settings as JSON
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDefaultUsingPost1$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDefaultUsingPost1$Json$Response(params: GetDefaultUsingPost1$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<JsonRouteResponse>> {
    return getDefaultUsingPost1$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Directions Service (POST).
   *
   * Returns a route between two or more locations for a selected profile and its settings as JSON
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDefaultUsingPost1$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDefaultUsingPost1$Json(params: GetDefaultUsingPost1$Json$Params, context?: HttpContext): Observable<JsonRouteResponse> {
    return this.getDefaultUsingPost1$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<JsonRouteResponse>): JsonRouteResponse => r.body)
    );
  }

  /** Path part for operation `getGeoJsonRouteUsingPost()` */
  static readonly GetGeoJsonRouteUsingPostPath = '/v2/directions/{profile}/geojson';

  /**
   * Directions Service GeoJSON (POST).
   *
   * Returns a route between two or more locations for a selected profile and its settings as GeoJSON
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGeoJsonRouteUsingPost$JsonCharsetUtf8()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getGeoJsonRouteUsingPost$JsonCharsetUtf8$Response(params: GetGeoJsonRouteUsingPost$JsonCharsetUtf8$Params, context?: HttpContext): Observable<StrictHttpResponse<GeoJsonRouteResponse>> {
    return getGeoJsonRouteUsingPost$JsonCharsetUtf8(this.http, this.rootUrl, params, context);
  }

  /**
   * Directions Service GeoJSON (POST).
   *
   * Returns a route between two or more locations for a selected profile and its settings as GeoJSON
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGeoJsonRouteUsingPost$JsonCharsetUtf8$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getGeoJsonRouteUsingPost$JsonCharsetUtf8(params: GetGeoJsonRouteUsingPost$JsonCharsetUtf8$Params, context?: HttpContext): Observable<GeoJsonRouteResponse> {
    return this.getGeoJsonRouteUsingPost$JsonCharsetUtf8$Response(params, context).pipe(
      map((r: StrictHttpResponse<GeoJsonRouteResponse>): GeoJsonRouteResponse => r.body)
    );
  }

  /**
   * Directions Service GeoJSON (POST).
   *
   * Returns a route between two or more locations for a selected profile and its settings as GeoJSON
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGeoJsonRouteUsingPost$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getGeoJsonRouteUsingPost$Json$Response(params: GetGeoJsonRouteUsingPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<GeoJsonRouteResponse>> {
    return getGeoJsonRouteUsingPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Directions Service GeoJSON (POST).
   *
   * Returns a route between two or more locations for a selected profile and its settings as GeoJSON
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGeoJsonRouteUsingPost$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getGeoJsonRouteUsingPost$Json(params: GetGeoJsonRouteUsingPost$Json$Params, context?: HttpContext): Observable<GeoJsonRouteResponse> {
    return this.getGeoJsonRouteUsingPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<GeoJsonRouteResponse>): GeoJsonRouteResponse => r.body)
    );
  }

  /** Path part for operation `getGpxRouteUsingPost()` */
  static readonly GetGpxRouteUsingPostPath = '/v2/directions/{profile}/gpx';

  /**
   * Directions Service GPX (POST).
   *
   * Returns a route between two or more locations for a selected profile and its settings as GPX. The schema can be found [here](https://raw.githubusercontent.com/GIScience/openrouteservice-schema/master/gpx/v1/ors-gpx.xsd)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGpxRouteUsingPost$XmlCharsetUtf8()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getGpxRouteUsingPost$XmlCharsetUtf8$Response(params: GetGpxRouteUsingPost$XmlCharsetUtf8$Params, context?: HttpContext): Observable<StrictHttpResponse<Gpx>> {
    return getGpxRouteUsingPost$XmlCharsetUtf8(this.http, this.rootUrl, params, context);
  }

  /**
   * Directions Service GPX (POST).
   *
   * Returns a route between two or more locations for a selected profile and its settings as GPX. The schema can be found [here](https://raw.githubusercontent.com/GIScience/openrouteservice-schema/master/gpx/v1/ors-gpx.xsd)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGpxRouteUsingPost$XmlCharsetUtf8$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getGpxRouteUsingPost$XmlCharsetUtf8(params: GetGpxRouteUsingPost$XmlCharsetUtf8$Params, context?: HttpContext): Observable<Gpx> {
    return this.getGpxRouteUsingPost$XmlCharsetUtf8$Response(params, context).pipe(
      map((r: StrictHttpResponse<Gpx>): Gpx => r.body)
    );
  }

  /**
   * Directions Service GPX (POST).
   *
   * Returns a route between two or more locations for a selected profile and its settings as GPX. The schema can be found [here](https://raw.githubusercontent.com/GIScience/openrouteservice-schema/master/gpx/v1/ors-gpx.xsd)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGpxRouteUsingPost$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getGpxRouteUsingPost$Xml$Response(params: GetGpxRouteUsingPost$Xml$Params, context?: HttpContext): Observable<StrictHttpResponse<Gpx>> {
    return getGpxRouteUsingPost$Xml(this.http, this.rootUrl, params, context);
  }

  /**
   * Directions Service GPX (POST).
   *
   * Returns a route between two or more locations for a selected profile and its settings as GPX. The schema can be found [here](https://raw.githubusercontent.com/GIScience/openrouteservice-schema/master/gpx/v1/ors-gpx.xsd)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGpxRouteUsingPost$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getGpxRouteUsingPost$Xml(params: GetGpxRouteUsingPost$Xml$Params, context?: HttpContext): Observable<Gpx> {
    return this.getGpxRouteUsingPost$Xml$Response(params, context).pipe(
      map((r: StrictHttpResponse<Gpx>): Gpx => r.body)
    );
  }

  /** Path part for operation `getJsonRouteUsingPost()` */
  static readonly GetJsonRouteUsingPostPath = '/v2/directions/{profile}/json';

  /**
   * Directions Service JSON (POST).
   *
   * Returns a route between two or more locations for a selected profile and its settings as JSON
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getJsonRouteUsingPost$JsonCharsetUtf8()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getJsonRouteUsingPost$JsonCharsetUtf8$Response(params: GetJsonRouteUsingPost$JsonCharsetUtf8$Params, context?: HttpContext): Observable<StrictHttpResponse<JsonRouteResponse>> {
    return getJsonRouteUsingPost$JsonCharsetUtf8(this.http, this.rootUrl, params, context);
  }

  /**
   * Directions Service JSON (POST).
   *
   * Returns a route between two or more locations for a selected profile and its settings as JSON
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getJsonRouteUsingPost$JsonCharsetUtf8$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getJsonRouteUsingPost$JsonCharsetUtf8(params: GetJsonRouteUsingPost$JsonCharsetUtf8$Params, context?: HttpContext): Observable<JsonRouteResponse> {
    return this.getJsonRouteUsingPost$JsonCharsetUtf8$Response(params, context).pipe(
      map((r: StrictHttpResponse<JsonRouteResponse>): JsonRouteResponse => r.body)
    );
  }

  /**
   * Directions Service JSON (POST).
   *
   * Returns a route between two or more locations for a selected profile and its settings as JSON
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getJsonRouteUsingPost$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getJsonRouteUsingPost$Json$Response(params: GetJsonRouteUsingPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<JsonRouteResponse>> {
    return getJsonRouteUsingPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Directions Service JSON (POST).
   *
   * Returns a route between two or more locations for a selected profile and its settings as JSON
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getJsonRouteUsingPost$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getJsonRouteUsingPost$Json(params: GetJsonRouteUsingPost$Json$Params, context?: HttpContext): Observable<JsonRouteResponse> {
    return this.getJsonRouteUsingPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<JsonRouteResponse>): JsonRouteResponse => r.body)
    );
  }

}
