import { Action } from '@ngrx/store';
import { Status } from '../../interfaces/status.enum';
import { ErrorResponse } from '@dispo-web/app/general/interfaces/error.interface';
import { Vehicle, VehicleType } from '@dispo-shared/open-api/models';
import { WebSocketTourUpdateMessage } from '@dispo-shared/open-api/models/web-socket-tour-update-message';

export enum VehiclesActionTypes {
  SetStatus = '[Vehicles] Set status',
  AddVehicles = '[Vehicles] Add vehicles',
  AddVehicle = '[Vehicles] Add vehicle',
  ClearVehicles = '[Vehicles] Clear vehicles',
  UpdateVehicle = '[Vehicles] Update vehicle',
  ResetVehicles = '[Vehicles] Reset vehicles',
  UpdateVehicleTour = '[Vehicles] Update tour value',
  SetVehicleTypes = '[Vehicles] Set vehicle types',
}

export class SetStatus implements Action {
  public readonly type = VehiclesActionTypes.SetStatus;
  constructor(public payload: { status: Status; error?: ErrorResponse | null }) {}
}

export class AddVehicles implements Action {
  public readonly type = VehiclesActionTypes.AddVehicles;
  constructor(public payload: { vehicles: Vehicle[] }) {}
}

export class AddVehicle implements Action {
  public readonly type = VehiclesActionTypes.AddVehicle;
  constructor(public payload: { vehicle: Vehicle }) {}
}

export class UpdateVehicle implements Action {
  public readonly type = VehiclesActionTypes.UpdateVehicle;
  constructor(public payload: { vehicle: Vehicle; oldVehicle?: Vehicle }) {}
}

export class UpdateVehicleTour implements Action {
  public readonly type = VehiclesActionTypes.UpdateVehicleTour;
  constructor(public payload: { tour: WebSocketTourUpdateMessage }) {}
}

export class SetVehicleTypes implements Action {
  public readonly type = VehiclesActionTypes.SetVehicleTypes;
  constructor(public payload: { vehicleTypes: VehicleType[] }) {}
}

export class ClearVehicles implements Action {
  public readonly type = VehiclesActionTypes.ClearVehicles;
}

export class ResetVehicles implements Action {
  public readonly type = VehiclesActionTypes.ResetVehicles;
}

export type VehiclesActions =
  | SetStatus
  | AddVehicles
  | AddVehicle
  | UpdateVehicle
  | SetVehicleTypes
  | ClearVehicles
  | ResetVehicles
  | UpdateVehicleTour;
