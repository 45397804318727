/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { StoredAddressTypesService } from './services/stored-address-types.service';
import { AddressesService } from './services/addresses.service';
import { AreasService } from './services/areas.service';
import { AssetsService } from './services/assets.service';
import { AuthenticationService } from './services/authentication.service';
import { BranchesService } from './services/branches.service';
import { CalendarRecursionExclusionsService } from './services/calendar-recursion-exclusions.service';
import { CalendarsService } from './services/calendars.service';
import { CalendarEventsService } from './services/calendar-events.service';
import { CalendarGroupsService } from './services/calendar-groups.service';
import { CasesService } from './services/cases.service';
import { MessagesService } from './services/messages.service';
import { DashboardService } from './services/dashboard.service';
import { DevicesService } from './services/devices.service';
import { ExportService } from './services/export.service';
import { RoadsideAssistanceCaseService } from './services/roadside-assistance-case.service';
import { ExternalService } from './services/external.service';
import { GeocodingService } from './services/geocoding.service';
import { RolesService } from './services/roles.service';
import { LocationsService } from './services/locations.service';
import { ResponsibilityClaimsService } from './services/responsibility-claims.service';
import { RoutingService } from './services/routing.service';
import { RsaDevicesService } from './services/rsa-devices.service';
import { RsaToursService } from './services/rsa-tours.service';
import { ServiceAccountUsersService } from './services/service-account-users.service';
import { SkillsService } from './services/skills.service';
import { TenantsService } from './services/tenants.service';
import { BrandsService } from './services/brands.service';
import { TodosTemplatesService } from './services/todos-templates.service';
import { ToursService } from './services/tours.service';
import { ScheduledToursService } from './services/scheduled-tours.service';
import { UsersService } from './services/users.service';
import { UserInvitesService } from './services/user-invites.service';
import { VehicleTypesService } from './services/vehicle-types.service';
import { VehiclesService } from './services/vehicles.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    StoredAddressTypesService,
    AddressesService,
    AreasService,
    AssetsService,
    AuthenticationService,
    BranchesService,
    CalendarRecursionExclusionsService,
    CalendarsService,
    CalendarEventsService,
    CalendarGroupsService,
    CasesService,
    MessagesService,
    DashboardService,
    DevicesService,
    ExportService,
    RoadsideAssistanceCaseService,
    ExternalService,
    GeocodingService,
    RolesService,
    LocationsService,
    ResponsibilityClaimsService,
    RoutingService,
    RsaDevicesService,
    RsaToursService,
    ServiceAccountUsersService,
    SkillsService,
    TenantsService,
    BrandsService,
    TodosTemplatesService,
    ToursService,
    ScheduledToursService,
    UsersService,
    UserInvitesService,
    VehicleTypesService,
    VehiclesService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
