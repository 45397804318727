import { Action } from '@ngrx/store';
import { Status } from '../../interfaces/status.enum';
import { Case, StoredAddress } from '@dispo-shared/open-api/models';

export enum CasesActionTypes {
  SetStatus = '[Cases] Set status',
  AddCases = '[Cases] Add cases',
  AddCase = '[Cases] Add case',
  ClearCases = '[Cases] Clear cases',
  UpdateCase = '[Cases] Update case',
  ResetCases = '[Cases] Reset cases',
  SetDefaultLocations = '[Cases] Set default locations',
}

export class SetStatus implements Action {
  public readonly type = CasesActionTypes.SetStatus;
  constructor(public payload: { status: Status; error?: string }) {}
}

export class AddCases implements Action {
  public readonly type = CasesActionTypes.AddCases;
  constructor(public payload: { cases: Case[] }) {}
}

export class AddCase implements Action {
  public readonly type = CasesActionTypes.AddCase;
  constructor(public payload: { case: Case }) {}
}

export class UpdateCase implements Action {
  public readonly type = CasesActionTypes.UpdateCase;
  constructor(public payload: { case: Case }) {}
}

export class ClearCases implements Action {
  public readonly type = CasesActionTypes.ClearCases;
}

export class ResetCases implements Action {
  public readonly type = CasesActionTypes.ResetCases;
}

export class SetDefaultLocations implements Action {
  public readonly type = CasesActionTypes.SetDefaultLocations;
  constructor(public payload: { locations: StoredAddress[] }) {}
}

export type CasesActions =
  | SetStatus
  | AddCases
  | AddCase
  | UpdateCase
  | ClearCases
  | ResetCases
  | SetDefaultLocations;
