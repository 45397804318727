/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { associateVehicle } from '../fn/devices/associate-vehicle';
import { AssociateVehicle$Params } from '../fn/devices/associate-vehicle';
import { createDevice } from '../fn/devices/create-device';
import { CreateDevice$Params } from '../fn/devices/create-device';
import { Device } from '../models/device';
import { DeviceListResponse } from '../models/device-list-response';
import { DeviceTypeResponse } from '../models/device-type-response';
import { getDevice } from '../fn/devices/get-device';
import { GetDevice$Params } from '../fn/devices/get-device';
import { getDevices } from '../fn/devices/get-devices';
import { GetDevices$Params } from '../fn/devices/get-devices';
import { getDeviceTypes } from '../fn/devices/get-device-types';
import { GetDeviceTypes$Params } from '../fn/devices/get-device-types';
import { resetPushToken } from '../fn/devices/reset-push-token';
import { ResetPushToken$Params } from '../fn/devices/reset-push-token';
import { sendRoboCall } from '../fn/devices/send-robo-call';
import { SendRoboCall$Params } from '../fn/devices/send-robo-call';
import { sendSms } from '../fn/devices/send-sms';
import { SendSms$Params } from '../fn/devices/send-sms';
import { updateDevice } from '../fn/devices/update-device';
import { UpdateDevice$Params } from '../fn/devices/update-device';
import { updateDeviceMetadata } from '../fn/devices/update-device-metadata';
import { UpdateDeviceMetadata$Params } from '../fn/devices/update-device-metadata';
import { updateInstalledAppInformation } from '../fn/devices/update-installed-app-information';
import { UpdateInstalledAppInformation$Params } from '../fn/devices/update-installed-app-information';
import { updatePushToken } from '../fn/devices/update-push-token';
import { UpdatePushToken$Params } from '../fn/devices/update-push-token';
import { updatePushTokenDeprecated } from '../fn/devices/update-push-token-deprecated';
import { UpdatePushTokenDeprecated$Params } from '../fn/devices/update-push-token-deprecated';

@Injectable({ providedIn: 'root' })
export class DevicesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDeviceTypes()` */
  static readonly GetDeviceTypesPath = '/api/v1/device_types';

  /**
   * Get list of device types.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeviceTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceTypes$Response(params?: GetDeviceTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<DeviceTypeResponse>> {
    return getDeviceTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of device types.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDeviceTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceTypes(params?: GetDeviceTypes$Params, context?: HttpContext): Observable<DeviceTypeResponse> {
    return this.getDeviceTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeviceTypeResponse>): DeviceTypeResponse => r.body)
    );
  }

  /** Path part for operation `getDevices()` */
  static readonly GetDevicesPath = '/api/v1/devices';

  /**
   * Get paginated list of this resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDevices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDevices$Response(params?: GetDevices$Params, context?: HttpContext): Observable<StrictHttpResponse<DeviceListResponse>> {
    return getDevices(this.http, this.rootUrl, params, context);
  }

  /**
   * Get paginated list of this resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDevices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDevices(params?: GetDevices$Params, context?: HttpContext): Observable<DeviceListResponse> {
    return this.getDevices$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeviceListResponse>): DeviceListResponse => r.body)
    );
  }

  /** Path part for operation `createDevice()` */
  static readonly CreateDevicePath = '/api/v1/devices';

  /**
   * Create new resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDevice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDevice$Response(params: CreateDevice$Params, context?: HttpContext): Observable<StrictHttpResponse<Device>> {
    return createDevice(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createDevice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDevice(params: CreateDevice$Params, context?: HttpContext): Observable<Device> {
    return this.createDevice$Response(params, context).pipe(
      map((r: StrictHttpResponse<Device>): Device => r.body)
    );
  }

  /** Path part for operation `resetPushToken()` */
  static readonly ResetPushTokenPath = '/api/v1/devices/reset_pushtoken';

  /**
   * Set to null push token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetPushToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetPushToken$Response(params?: ResetPushToken$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return resetPushToken(this.http, this.rootUrl, params, context);
  }

  /**
   * Set to null push token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resetPushToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetPushToken(params?: ResetPushToken$Params, context?: HttpContext): Observable<void> {
    return this.resetPushToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updatePushToken()` */
  static readonly UpdatePushTokenPath = '/api/v1/devices/update_pushtoken';

  /**
   * Update existing resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePushToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePushToken$Response(params: UpdatePushToken$Params, context?: HttpContext): Observable<StrictHttpResponse<Device>> {
    return updatePushToken(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePushToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePushToken(params: UpdatePushToken$Params, context?: HttpContext): Observable<Device> {
    return this.updatePushToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<Device>): Device => r.body)
    );
  }

  /** Path part for operation `getDevice()` */
  static readonly GetDevicePath = '/api/v1/devices/{id}';

  /**
   * Get existing resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDevice()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDevice$Response(params: GetDevice$Params, context?: HttpContext): Observable<StrictHttpResponse<Device>> {
    return getDevice(this.http, this.rootUrl, params, context);
  }

  /**
   * Get existing resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDevice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDevice(params: GetDevice$Params, context?: HttpContext): Observable<Device> {
    return this.getDevice$Response(params, context).pipe(
      map((r: StrictHttpResponse<Device>): Device => r.body)
    );
  }

  /** Path part for operation `updateDevice()` */
  static readonly UpdateDevicePath = '/api/v1/devices/{id}';

  /**
   * Update existing resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDevice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDevice$Response(params: UpdateDevice$Params, context?: HttpContext): Observable<StrictHttpResponse<Device>> {
    return updateDevice(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDevice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDevice(params: UpdateDevice$Params, context?: HttpContext): Observable<Device> {
    return this.updateDevice$Response(params, context).pipe(
      map((r: StrictHttpResponse<Device>): Device => r.body)
    );
  }

  /** Path part for operation `associateVehicle()` */
  static readonly AssociateVehiclePath = '/api/v1/devices/{id}/associate/vehicle';

  /**
   * Create new vehiclke association.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `associateVehicle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  associateVehicle$Response(params: AssociateVehicle$Params, context?: HttpContext): Observable<StrictHttpResponse<Device>> {
    return associateVehicle(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new vehiclke association.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `associateVehicle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  associateVehicle(params: AssociateVehicle$Params, context?: HttpContext): Observable<Device> {
    return this.associateVehicle$Response(params, context).pipe(
      map((r: StrictHttpResponse<Device>): Device => r.body)
    );
  }

  /** Path part for operation `updateInstalledAppInformation()` */
  static readonly UpdateInstalledAppInformationPath = '/api/v1/devices/{id}/installed_app_info';

  /**
   * Update existing resource installed app information.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateInstalledAppInformation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateInstalledAppInformation$Response(params: UpdateInstalledAppInformation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateInstalledAppInformation(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing resource installed app information.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateInstalledAppInformation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateInstalledAppInformation(params: UpdateInstalledAppInformation$Params, context?: HttpContext): Observable<void> {
    return this.updateInstalledAppInformation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sendSms()` */
  static readonly SendSmsPath = '/api/v1/devices/{id}/send_message';

  /**
   * Send sms to specific device phone number.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendSms()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendSms$Response(params: SendSms$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sendSms(this.http, this.rootUrl, params, context);
  }

  /**
   * Send sms to specific device phone number.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendSms$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendSms(params: SendSms$Params, context?: HttpContext): Observable<void> {
    return this.sendSms$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sendRoboCall()` */
  static readonly SendRoboCallPath = '/api/v1/devices/{id}/send_robo_call';

  /**
   * Send robo call to specific device phone number.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendRoboCall()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendRoboCall$Response(params: SendRoboCall$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sendRoboCall(this.http, this.rootUrl, params, context);
  }

  /**
   * Send robo call to specific device phone number.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendRoboCall$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendRoboCall(params: SendRoboCall$Params, context?: HttpContext): Observable<void> {
    return this.sendRoboCall$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateDeviceMetadata()` */
  static readonly UpdateDeviceMetadataPath = '/api/v1/devices/{id}/update_metadata';

  /**
   * Update existing resource meta data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDeviceMetadata()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDeviceMetadata$Response(params: UpdateDeviceMetadata$Params, context?: HttpContext): Observable<StrictHttpResponse<Device>> {
    return updateDeviceMetadata(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing resource meta data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDeviceMetadata$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDeviceMetadata(params: UpdateDeviceMetadata$Params, context?: HttpContext): Observable<Device> {
    return this.updateDeviceMetadata$Response(params, context).pipe(
      map((r: StrictHttpResponse<Device>): Device => r.body)
    );
  }

  /** Path part for operation `updatePushTokenDeprecated()` */
  static readonly UpdatePushTokenDeprecatedPath = '/api/v1/devices/{id}/update_pushtoken';

  /**
   * Update existing resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePushTokenDeprecated()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePushTokenDeprecated$Response(params: UpdatePushTokenDeprecated$Params, context?: HttpContext): Observable<StrictHttpResponse<Device>> {
    return updatePushTokenDeprecated(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePushTokenDeprecated$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePushTokenDeprecated(params: UpdatePushTokenDeprecated$Params, context?: HttpContext): Observable<Device> {
    return this.updatePushTokenDeprecated$Response(params, context).pipe(
      map((r: StrictHttpResponse<Device>): Device => r.body)
    );
  }

}
