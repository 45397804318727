/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { acknowledgeMessage } from '../fn/messages/acknowledge-message';
import { AcknowledgeMessage$Params } from '../fn/messages/acknowledge-message';
import { getCriticalMessages } from '../fn/messages/get-critical-messages';
import { GetCriticalMessages$Params } from '../fn/messages/get-critical-messages';
import { Message } from '../models/message';
import { MessageListResponse } from '../models/message-list-response';

@Injectable({ providedIn: 'root' })
export class MessagesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCriticalMessages()` */
  static readonly GetCriticalMessagesPath = '/api/v1/critical_messages';

  /**
   * Get list of this resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCriticalMessages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCriticalMessages$Response(params?: GetCriticalMessages$Params, context?: HttpContext): Observable<StrictHttpResponse<MessageListResponse>> {
    return getCriticalMessages(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of this resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCriticalMessages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCriticalMessages(params?: GetCriticalMessages$Params, context?: HttpContext): Observable<MessageListResponse> {
    return this.getCriticalMessages$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessageListResponse>): MessageListResponse => r.body)
    );
  }

  /** Path part for operation `acknowledgeMessage()` */
  static readonly AcknowledgeMessagePath = '/api/v1/critical_messages/{id}/acknowledge';

  /**
   * Update resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acknowledgeMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  acknowledgeMessage$Response(params: AcknowledgeMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<Message>> {
    return acknowledgeMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * Update resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `acknowledgeMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  acknowledgeMessage(params: AcknowledgeMessage$Params, context?: HttpContext): Observable<Message> {
    return this.acknowledgeMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<Message>): Message => r.body)
    );
  }

}
