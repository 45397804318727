import { enableProdMode, APP_INITIALIZER, ErrorHandler, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { EnvHelper } from '@dispo-shared/shared-ui/helper/env-helper/env-helper';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { CustomToastsComponent } from './app/business-domains/funeral/shared/custom-toasts/custom-toasts.component';
import { ToastrModule } from 'ngx-toastr';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { forkJoin, map } from 'rxjs';
import { ApiModule as OrsApiModule } from '@dispo-shared/ors-api/api.module';
import { SpaConfig } from '@dispo-shared/spa-config/spa-config';
import { ApiModule } from '@dispo-shared/open-api/api.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import { UsersEffects } from './app/general/store/effects/users.effect';
import { VehiclesEffects } from './app/general/store/effects/vehicles.effect';
import { DevicesEffects } from './app/general/store/effects/devices.effect';
import { AuthEffects } from './app/general/store/effects/auth.effect';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './app/general/store';
import { StoreModule } from '@ngrx/store';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { AppRoutingModule } from './app/app-routing.module';
import { FuneralRouteReuseStrategy } from './app/business-domains/funeral/funeral-route-reuse-strategy';
import { GeoRouteService } from './app/general/services/geo-route/geo-route.service';
import { Title, BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import { AntiCacheInterceptor } from './app/general/interceptors/anti-cache.interceptor';
import { ETagInterceptor } from './app/general/interceptors/eTag.interceptor';
import { ErrorInterceptor } from './app/general/interceptors/error.interceptor';
import { TokenInterceptor } from './app/general/interceptors/token.interceptor';
import {
  HTTP_INTERCEPTORS,
  withInterceptorsFromDi,
  provideHttpClient,
  HttpClient,
} from '@angular/common/http';
import { TenantConfigService } from './app/general/services/tenant-config.service';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
registerLocaleData(localeDe, 'de-DE', localeDeExtra);

function createTranslateLoader(prefixes: string[] = ['./assets/i18n/']) {
  return (http: HttpClient) => {
    return {
      getTranslation: (lang: string) =>
        forkJoin(
          prefixes.map((prefix) => {
            return new TranslateHttpLoader(http, prefix, '.json').getTranslation(lang);
          })
        ).pipe(
          map((translations) => {
            return translations.reduce((acc, translations2) => {
              return { ...acc, ...translations2 };
            }, {});
          })
        ),
    };
  };
}

export function initialise(translate: TranslateService, tenantService: TenantConfigService): any {
  return async () => {
    // Supported languages
    translate.addLangs(['de', 'en']);
    // User german as default
    translate.setDefaultLang('de');

    // Set translation to browser language if we support it
    const browserLang = translate.getBrowserLang() ?? 'de';
    if (translate.langs.indexOf(browserLang) < 0) {
      translate.use('de');
    } else {
      translate.use(browserLang);
    }

    const translatePromise = new Promise<void>((resolve) => {
      translate.onLangChange.subscribe(() => {
        resolve();
      });
    });
    const tenantPromise = tenantService.loaded;

    return Promise.all([translatePromise, tenantPromise]);
  };
}

const tenantShortCode: string = window.location.host.split('.')?.[0]?.split(':')?.[0];

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: 'https://52cf22004f614edb1ba13bce57e67f7c@o684811.ingest.us.sentry.io/4507216379641856',
  environment: EnvHelper.getWebEnv(),
  enabled: environment.production,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  initialScope: {
    tags: { tenant: tenantShortCode },
  },
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      FlexLayoutModule,
      MessagesModule,
      MessageModule,
      StoreModule.forRoot(reducers),
      EffectsModule.forRoot([AuthEffects, DevicesEffects, VehiclesEffects, UsersEffects]),
      ButtonModule,
      ApiModule.forRoot({ rootUrl: SpaConfig.apiUrlProvider.rootUrl }),
      OrsApiModule.forRoot({ rootUrl: 'https://ors.geo.openresearch.com/ors' }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader(['./assets/i18n/', './assets/i18n/shared-ui/']),
          deps: [HttpClient],
        },
      }),
      StoreDevtoolsModule.instrument({
        maxAge: 25,
        logOnly: environment.production,
      }),
      ToastrModule.forRoot({
        toastComponent: CustomToastsComponent,
        toastClass: 'toastr',
        closeButton: true,
        tapToDismiss: false,
      })
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: initialise,
      deps: [TranslateService, TenantConfigService],
      multi: true,
    },
    { provide: 'environment', useValue: environment },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ETagInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AntiCacheInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    Title,
    GeoRouteService,
    { provide: RouteReuseStrategy, useClass: FuneralRouteReuseStrategy },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
}).catch((err) => console.error(err));
