/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GeoJsonRouteResponse } from '../../models/geo-json-route-response';

export interface GetSimpleGeoJsonRouteUsingGet$Params {

/**
 * Destination coordinate of the route
 */
  end: string;

/**
 * Specifies the route profile.
 */
  profile: 'driving-car' | 'driving-hgv' | 'cycling-regular' | 'cycling-road' | 'cycling-mountain' | 'cycling-electric' | 'foot-walking' | 'foot-hiking' | 'wheelchair';

/**
 * Start coordinate of the route
 */
  start: string;
}

export function getSimpleGeoJsonRouteUsingGet(http: HttpClient, rootUrl: string, params: GetSimpleGeoJsonRouteUsingGet$Params, context?: HttpContext): Observable<StrictHttpResponse<GeoJsonRouteResponse>> {
  const rb = new RequestBuilder(rootUrl, getSimpleGeoJsonRouteUsingGet.PATH, 'get');
  if (params) {
    rb.query('end', params.end, {});
    rb.path('profile', params.profile, {});
    rb.query('start', params.start, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/geo+json;charset=UTF-8', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GeoJsonRouteResponse>;
    })
  );
}

getSimpleGeoJsonRouteUsingGet.PATH = '/v2/directions/{profile}';
