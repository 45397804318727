/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SuggestedAddressListResponse } from '../../models/suggested-address-list-response';

export interface ForwardSearch$Params {
  location: string;
  lat?: number;
  lon?: number;
  geocoder?: string;
}

export function forwardSearch(http: HttpClient, rootUrl: string, params: ForwardSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<SuggestedAddressListResponse>> {
  const rb = new RequestBuilder(rootUrl, forwardSearch.PATH, 'post');
  if (params) {
    rb.query('location', params.location, {"style":"form","explode":true});
    rb.query('lat', params.lat, {"style":"form","explode":true});
    rb.query('lon', params.lon, {"style":"form","explode":true});
    rb.query('geocoder', params.geocoder, {"style":"form","explode":true});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SuggestedAddressListResponse>;
    })
  );
}

forwardSearch.PATH = '/api/v1/geocoding/addresses/forward_search';
