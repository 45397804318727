/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CalendarEventExclusionListResponse } from '../../models/calendar-event-exclusion-list-response';

export interface GetEventExclusions$Params {

/**
 * Id of a calendar event.
 */
  eventId: string;
}

export function getEventExclusions(http: HttpClient, rootUrl: string, params: GetEventExclusions$Params, context?: HttpContext): Observable<StrictHttpResponse<CalendarEventExclusionListResponse>> {
  const rb = new RequestBuilder(rootUrl, getEventExclusions.PATH, 'get');
  if (params) {
    rb.path('eventId', params.eventId, {"style":"simple","explode":false});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CalendarEventExclusionListResponse>;
    })
  );
}

getEventExclusions.PATH = '/api/v1/calendarEvents/{eventId}/exclusions';
