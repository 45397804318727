/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createStoredAddressTypes } from '../fn/stored-address-types/create-stored-address-types';
import { CreateStoredAddressTypes$Params } from '../fn/stored-address-types/create-stored-address-types';
import { getStoredAddressTypes } from '../fn/stored-address-types/get-stored-address-types';
import { GetStoredAddressTypes$Params } from '../fn/stored-address-types/get-stored-address-types';
import { StoredAddressTypesListResponse } from '../models/stored-address-types-list-response';

@Injectable({ providedIn: 'root' })
export class StoredAddressTypesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getStoredAddressTypes()` */
  static readonly GetStoredAddressTypesPath = '/api/v1/address_types';

  /**
   * Get stored address types.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStoredAddressTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStoredAddressTypes$Response(params?: GetStoredAddressTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<StoredAddressTypesListResponse>> {
    return getStoredAddressTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get stored address types.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStoredAddressTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStoredAddressTypes(params?: GetStoredAddressTypes$Params, context?: HttpContext): Observable<StoredAddressTypesListResponse> {
    return this.getStoredAddressTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<StoredAddressTypesListResponse>): StoredAddressTypesListResponse => r.body)
    );
  }

  /** Path part for operation `createStoredAddressTypes()` */
  static readonly CreateStoredAddressTypesPath = '/api/v1/address_types';

  /**
   * Create stored addresse types.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createStoredAddressTypes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createStoredAddressTypes$Response(params: CreateStoredAddressTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<StoredAddressTypesListResponse>> {
    return createStoredAddressTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Create stored addresse types.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createStoredAddressTypes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createStoredAddressTypes(params: CreateStoredAddressTypes$Params, context?: HttpContext): Observable<StoredAddressTypesListResponse> {
    return this.createStoredAddressTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<StoredAddressTypesListResponse>): StoredAddressTypesListResponse => r.body)
    );
  }

}
