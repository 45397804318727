import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromVehiclesAction from '../actions/vehicles.action';
import { tap } from 'rxjs/operators';
import { DevicesStoreService } from '@dispo-web/app/business-domains/funeral/services/devices-store.service';

@Injectable()
export class VehiclesEffects {
  private devicesStoreService = inject(DevicesStoreService);

  private actions$: Actions = inject(Actions);

  public update$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<fromVehiclesAction.UpdateVehicle>(
          fromVehiclesAction.VehiclesActionTypes.UpdateVehicle
        ),
        tap((action) => {
          if (action.payload.oldVehicle) {
            if (action.payload.vehicle.active_device_association?.device_id) {
              // Update new association
              this.devicesStoreService.getDeviceById(
                action.payload.vehicle.active_device_association.device_id
              );
            }
            if (action.payload.oldVehicle.active_device_association?.device_id) {
              // Remove old association
              this.devicesStoreService.getDeviceById(
                action.payload.oldVehicle.active_device_association.device_id
              );
            }
          }
        })
      );
    },
    { dispatch: false }
  );
}
