import * as fromAuthReducer from '../reducers/auth.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectAuthState = createFeatureSelector<fromAuthReducer.AuthState>('auth');
export const selectStatus = createSelector(selectAuthState, (state) => state.status);
export const selectError = createSelector(selectAuthState, (state) => state.error);
export const selectUser = createSelector(selectAuthState, (state) => state.user);
export const selectRoles = createSelector(selectAuthState, (state) => state.user?.roles);
export const selectGrants = createSelector(selectAuthState, (state) => state.user?.grants);
export const selectEmailAddress = createSelector(
  selectAuthState,
  (state) => state.user?.user?.email
);
