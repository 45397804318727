/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { changePassword } from '../fn/users/change-password';
import { ChangePassword$Params } from '../fn/users/change-password';
import { changePasswordRequest } from '../fn/users/change-password-request';
import { ChangePasswordRequest$Params } from '../fn/users/change-password-request';
import { createUser } from '../fn/users/create-user';
import { CreateUser$Params } from '../fn/users/create-user';
import { getUser } from '../fn/users/get-user';
import { GetUser$Params } from '../fn/users/get-user';
import { getUsers } from '../fn/users/get-users';
import { GetUsers$Params } from '../fn/users/get-users';
import { hardDeleteUser } from '../fn/users/hard-delete-user';
import { HardDeleteUser$Params } from '../fn/users/hard-delete-user';
import { removeRoleBranchAllAssociations } from '../fn/users/remove-role-branch-all-associations';
import { RemoveRoleBranchAllAssociations$Params } from '../fn/users/remove-role-branch-all-associations';
import { removeRoleBranchGroupAssociations } from '../fn/users/remove-role-branch-group-associations';
import { RemoveRoleBranchGroupAssociations$Params } from '../fn/users/remove-role-branch-group-associations';
import { resendSetPasswordEmail } from '../fn/users/resend-set-password-email';
import { ResendSetPasswordEmail$Params } from '../fn/users/resend-set-password-email';
import { unblockUser } from '../fn/users/unblock-user';
import { UnblockUser$Params } from '../fn/users/unblock-user';
import { updateUser } from '../fn/users/update-user';
import { UpdateUser$Params } from '../fn/users/update-user';
import { updateUserActiveState } from '../fn/users/update-user-active-state';
import { UpdateUserActiveState$Params } from '../fn/users/update-user-active-state';
import { User } from '../models/user';
import { UserListResponse } from '../models/user-list-response';

@Injectable({ providedIn: 'root' })
export class UsersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `changePassword()` */
  static readonly ChangePasswordPath = '/api/v1/user/change_password';

  /**
   * Change the users password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePassword$Response(params: ChangePassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return changePassword(this.http, this.rootUrl, params, context);
  }

  /**
   * Change the users password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePassword(params: ChangePassword$Params, context?: HttpContext): Observable<void> {
    return this.changePassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `changePasswordRequest()` */
  static readonly ChangePasswordRequestPath = '/api/v1/user/change_password_request';

  /**
   * Request a change for the users own password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changePasswordRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  changePasswordRequest$Response(params?: ChangePasswordRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return changePasswordRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * Request a change for the users own password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changePasswordRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  changePasswordRequest(params?: ChangePasswordRequest$Params, context?: HttpContext): Observable<void> {
    return this.changePasswordRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `resendSetPasswordEmail()` */
  static readonly ResendSetPasswordEmailPath = '/api/v1/user/{id}/resend_set_password_email';

  /**
   * Resend a mail for the user to set their password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resendSetPasswordEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  resendSetPasswordEmail$Response(params: ResendSetPasswordEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return resendSetPasswordEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * Resend a mail for the user to set their password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resendSetPasswordEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resendSetPasswordEmail(params: ResendSetPasswordEmail$Params, context?: HttpContext): Observable<void> {
    return this.resendSetPasswordEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `unblockUser()` */
  static readonly UnblockUserPath = '/api/v1/user/{id}/unblock';

  /**
   * Unblocks a user so that they can login again.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unblockUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  unblockUser$Response(params: UnblockUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return unblockUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Unblocks a user so that they can login again.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unblockUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unblockUser(params: UnblockUser$Params, context?: HttpContext): Observable<void> {
    return this.unblockUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getUsers()` */
  static readonly GetUsersPath = '/api/v1/users';

  /**
   * Get paginated list of this resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Response(params?: GetUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<UserListResponse>> {
    return getUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get paginated list of this resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers(params?: GetUsers$Params, context?: HttpContext): Observable<UserListResponse> {
    return this.getUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserListResponse>): UserListResponse => r.body)
    );
  }

  /** Path part for operation `createUser()` */
  static readonly CreateUserPath = '/api/v1/users';

  /**
   * Create new resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser$Response(params: CreateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return createUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser(params: CreateUser$Params, context?: HttpContext): Observable<User> {
    return this.createUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `getUser()` */
  static readonly GetUserPath = '/api/v1/users/{id}';

  /**
   * Get resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Response(params: GetUser$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return getUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Get resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser(params: GetUser$Params, context?: HttpContext): Observable<User> {
    return this.getUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `updateUser()` */
  static readonly UpdateUserPath = '/api/v1/users/{id}';

  /**
   * Update existing resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser$Response(params: UpdateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return updateUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser(params: UpdateUser$Params, context?: HttpContext): Observable<User> {
    return this.updateUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `updateUserActiveState()` */
  static readonly UpdateUserActiveStatePath = '/api/v1/users/{id}/active_state';

  /**
   * Update active state per branch_group_id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserActiveState()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserActiveState$Response(params: UpdateUserActiveState$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return updateUserActiveState(this.http, this.rootUrl, params, context);
  }

  /**
   * Update active state per branch_group_id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserActiveState$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserActiveState(params: UpdateUserActiveState$Params, context?: HttpContext): Observable<User> {
    return this.updateUserActiveState$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `hardDeleteUser()` */
  static readonly HardDeleteUserPath = '/api/v1/users/{id}/inactive';

  /**
   * Deletes user's information if user is already inactive.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hardDeleteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  hardDeleteUser$Response(params: HardDeleteUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return hardDeleteUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes user's information if user is already inactive.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hardDeleteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hardDeleteUser(params: HardDeleteUser$Params, context?: HttpContext): Observable<void> {
    return this.hardDeleteUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `removeRoleBranchAllAssociations()` */
  static readonly RemoveRoleBranchAllAssociationsPath = '/api/v1/users/{id}/role_branch_all_associations';

  /**
   * Removes all role associations for a user with branch access ALL.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeRoleBranchAllAssociations()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeRoleBranchAllAssociations$Response(params: RemoveRoleBranchAllAssociations$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeRoleBranchAllAssociations(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes all role associations for a user with branch access ALL.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeRoleBranchAllAssociations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeRoleBranchAllAssociations(params: RemoveRoleBranchAllAssociations$Params, context?: HttpContext): Observable<void> {
    return this.removeRoleBranchAllAssociations$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `removeRoleBranchGroupAssociations()` */
  static readonly RemoveRoleBranchGroupAssociationsPath = '/api/v1/users/{id}/role_branch_group_associations';

  /**
   * Removes all role associations for a user for a specific branch group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeRoleBranchGroupAssociations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeRoleBranchGroupAssociations$Response(params: RemoveRoleBranchGroupAssociations$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeRoleBranchGroupAssociations(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes all role associations for a user for a specific branch group.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeRoleBranchGroupAssociations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeRoleBranchGroupAssociations(params: RemoveRoleBranchGroupAssociations$Params, context?: HttpContext): Observable<void> {
    return this.removeRoleBranchGroupAssociations$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
