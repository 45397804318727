import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Status } from '@dispo-web/app/general/interfaces/status.enum';
import {
  SetManageDialogRequestStatus,
  SetAssociationSidebarRequestStatus,
} from '@dispo-web/app/general/store/actions/device-sidebar.action';
import {
  AddDevice,
  AddDevices,
  SetStatus,
  UpdateDevice,
} from '@dispo-web/app/general/store/actions/devices.action';
import { DeviceSidebarsState } from '@dispo-web/app/general/store/reducers/device-sidebar.reducer';
import { DevicesState } from '@dispo-web/app/general/store/reducers/devices.reducer';
import { DevicesService } from '@dispo-shared/open-api/services';
import { Device, DeviceCreateRequest, DeviceUpdateRequest } from '@dispo-shared/open-api/models';

@Injectable({
  providedIn: 'root',
})
export class DevicesStoreService {
  private deviceStore = inject<Store<DevicesState>>(Store);
  private deviceSidebarsStore = inject<Store<DeviceSidebarsState>>(Store);
  private deviceService = inject(DevicesService);


  getAllDevice(): void {
    this.deviceStore.dispatch(new SetStatus({ status: Status.Pending }));
    this.deviceService
      .getDevices({
        size: 1000,
        sort: ['createdAt'],
      })
      .subscribe({
        next: (response) => {
          if (response && response.devices) {
            this.deviceStore.dispatch(new AddDevices({ devices: response.devices }));
            this.deviceStore.dispatch(new SetStatus({ status: Status.Success }));
          } else {
            this.deviceStore.dispatch(
              new SetStatus({
                status: Status.Failed,
                error: {
                  args: [],
                  code: '000000',
                  message: 'No vehicles object in the response',
                  time: Date.now.toString(),
                  title: 'Empty response',
                },
              })
            );
          }
        },
        error: (error) => {
          console.error(`[DeviceService][getAllDevice]`, error);
          this.deviceStore.dispatch(new SetStatus({ status: Status.Failed, error }));
        },
      });
  }

  createDevice(device: DeviceCreateRequest): void {
    this.deviceSidebarsStore.dispatch(new SetManageDialogRequestStatus({ status: Status.Pending }));
    this.deviceService
      .createDevice({
        body: device,
      })
      .subscribe({
        next: (response) => {
          if (response) {
            this.deviceStore.dispatch(new AddDevice({ device: response }));
            this.deviceSidebarsStore.dispatch(
              new SetManageDialogRequestStatus({ status: Status.Success })
            );
          } else {
            this.deviceSidebarsStore.dispatch(
              new SetManageDialogRequestStatus({
                status: Status.Failed,
                error: 'No response object',
              })
            );
          }
        },
        error: (error) => {
          console.error(`[DeviceService][createDevice]`, error);
          this.deviceSidebarsStore.dispatch(
            new SetManageDialogRequestStatus({ status: Status.Failed, error })
          );
        },
      });
  }

  editDevice(deviceId: string, device: DeviceUpdateRequest): void {
    this.deviceSidebarsStore.dispatch(new SetManageDialogRequestStatus({ status: Status.Pending }));
    this.deviceService
      .updateDevice({
        id: deviceId,
        body: device,
      })
      .subscribe({
        next: (response) => {
          if (response) {
            this.deviceStore.dispatch(new UpdateDevice({ device: response }));
            this.deviceSidebarsStore.dispatch(
              new SetManageDialogRequestStatus({ status: Status.Success })
            );
          } else {
            this.deviceSidebarsStore.dispatch(
              new SetManageDialogRequestStatus({
                status: Status.Failed,
                error: 'No response object',
              })
            );
          }
        },
        error: (error) => {
          console.error(`[DeviceService][editDevice]`, error);
          this.deviceSidebarsStore.dispatch(
            new SetManageDialogRequestStatus({ status: Status.Failed, error })
          );
        },
      });
  }

  changeAssociation(device: Device, vehicle_id: string): void {
    if (!device.id) {
      return;
    }

    this.deviceSidebarsStore.dispatch(
      new SetAssociationSidebarRequestStatus({ status: Status.Pending })
    );
    this.deviceService
      .associateVehicle({
        id: device.id,
        body: {
          vehicle_id,
        },
      })
      .subscribe({
        next: (response) => {
          if (response) {
            this.deviceStore.dispatch(new UpdateDevice({ device: response, oldDevice: device }));
            this.deviceSidebarsStore.dispatch(
              new SetAssociationSidebarRequestStatus({ status: Status.Success })
            );
          } else {
            this.deviceSidebarsStore.dispatch(
              new SetAssociationSidebarRequestStatus({
                status: Status.Failed,
                error: 'No response object',
              })
            );
          }
        },
        error: (error) => {
          console.error(`[DeviceService][editDevice]`, error);
          this.deviceSidebarsStore.dispatch(
            new SetAssociationSidebarRequestStatus({
              status: Status.Failed,
              error,
            })
          );
        },
      });
  }

  getDeviceById(id: string): void {
    this.deviceStore.dispatch(new SetStatus({ status: Status.Pending }));
    this.deviceService
      .getDevice({
        id,
        include_associations: true,
      })
      .subscribe({
        next: (response) => {
          if (response) {
            this.deviceStore.dispatch(new UpdateDevice({ device: response }));
            this.deviceStore.dispatch(new SetStatus({ status: Status.Success }));
          } else {
            this.deviceStore.dispatch(
              new SetStatus({
                status: Status.Failed,
                error: {
                  args: [],
                  code: '000000',
                  message: 'No devices object in the response',
                  time: Date.now.toString(),
                  title: 'Empty response',
                },
              })
            );
          }
        },
        error: (error) => {
          console.error(`[DeviceService][getDeviceById]`, error);
          this.deviceStore.dispatch(new SetStatus({ status: Status.Failed, error }));
        },
      });
  }
}
