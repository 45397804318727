import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UpdateUser, UsersActionTypes } from '../actions/users.action';
import { take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AuthState } from '../reducers/auth.reducer';
import { selectUser } from '../selectors/auth.selector';
import { AuthService } from '@dispo-web/app/general/services/auth.service';
import { Login, SetStatus } from '../actions/auth.action';
import { Status } from '@dispo-web/app/general/interfaces/status.enum';
import { LoginResponse } from '@dispo-web/app/general/interfaces/auth.interface';

@Injectable()
export class UsersEffects {
  private authStore = inject<Store<AuthState>>(Store);
  private authService = inject(AuthService);

  private actions$: Actions = inject(Actions);

  public update$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<UpdateUser>(UsersActionTypes.UpdateUser),
        tap((action) => {
          if (action.payload.user) {
            // get the current user
            this.authStore
              .select(selectUser)
              .pipe(take(1))
              .subscribe((res) => {
                // check if the changed user was the current user
                if (res?.user.id === action.payload.user.id) {
                  // refresh the user access token
                  this.authService.refreshAccessToken().subscribe((refreshTokenResponse) => {
                    if (
                      typeof refreshTokenResponse !== 'string' &&
                      refreshTokenResponse.access_token &&
                      refreshTokenResponse.access_token.valid_until &&
                      refreshTokenResponse.access_token.value &&
                      refreshTokenResponse.refresh_token &&
                      refreshTokenResponse.refresh_token.valid_until &&
                      refreshTokenResponse.refresh_token.value &&
                      refreshTokenResponse.id
                    ) {
                      this.authStore.dispatch(
                        new Login({
                          tokens: refreshTokenResponse as LoginResponse,
                          refresh: false,
                        })
                      );
                      this.authStore.dispatch(new SetStatus({ status: Status.Success }));
                    }
                  });
                }
              });
          }
        })
      );
    },
    { dispatch: false }
  );
}
