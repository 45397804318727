/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { associateCasesWithTours } from '../fn/tours/associate-cases-with-tours';
import { AssociateCasesWithTours$Params } from '../fn/tours/associate-cases-with-tours';
import { createTour } from '../fn/tours/create-tour';
import { CreateTour$Params } from '../fn/tours/create-tour';
import { duplicateTour } from '../fn/tours/duplicate-tour';
import { DuplicateTour$Params } from '../fn/tours/duplicate-tour';
import { finishTour } from '../fn/tours/finish-tour';
import { FinishTour$Params } from '../fn/tours/finish-tour';
import { getStartedTourByVehicle } from '../fn/tours/get-started-tour-by-vehicle';
import { GetStartedTourByVehicle$Params } from '../fn/tours/get-started-tour-by-vehicle';
import { getTour } from '../fn/tours/get-tour';
import { GetTour$Params } from '../fn/tours/get-tour';
import { getTourByNaturalId } from '../fn/tours/get-tour-by-natural-id';
import { GetTourByNaturalId$Params } from '../fn/tours/get-tour-by-natural-id';
import { getTours } from '../fn/tours/get-tours';
import { GetTours$Params } from '../fn/tours/get-tours';
import { PaginatedTourListResponse } from '../models/paginated-tour-list-response';
import { requestTourLocationUpdate } from '../fn/tours/request-tour-location-update';
import { RequestTourLocationUpdate$Params } from '../fn/tours/request-tour-location-update';
import { requestToursLocationUpdate } from '../fn/tours/request-tours-location-update';
import { RequestToursLocationUpdate$Params } from '../fn/tours/request-tours-location-update';
import { startExistingTour } from '../fn/tours/start-existing-tour';
import { StartExistingTour$Params } from '../fn/tours/start-existing-tour';
import { Tour } from '../models/tour';
import { TourListResponse } from '../models/tour-list-response';
import { updateStartedSubState } from '../fn/tours/update-started-sub-state';
import { UpdateStartedSubState$Params } from '../fn/tours/update-started-sub-state';

@Injectable({ providedIn: 'root' })
export class ToursService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTours()` */
  static readonly GetToursPath = '/api/v1/tours';

  /**
   * Get paginated list of this resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTours()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTours$Response(params?: GetTours$Params, context?: HttpContext): Observable<StrictHttpResponse<PaginatedTourListResponse>> {
    return getTours(this.http, this.rootUrl, params, context);
  }

  /**
   * Get paginated list of this resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTours$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTours(params?: GetTours$Params, context?: HttpContext): Observable<PaginatedTourListResponse> {
    return this.getTours$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaginatedTourListResponse>): PaginatedTourListResponse => r.body)
    );
  }

  /** Path part for operation `associateCasesWithTours()` */
  static readonly AssociateCasesWithToursPath = '/api/v1/tours/associated_cases';

  /**
   * Associate cases with tour.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `associateCasesWithTours()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  associateCasesWithTours$Response(params: AssociateCasesWithTours$Params, context?: HttpContext): Observable<StrictHttpResponse<TourListResponse>> {
    return associateCasesWithTours(this.http, this.rootUrl, params, context);
  }

  /**
   * Associate cases with tour.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `associateCasesWithTours$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  associateCasesWithTours(params: AssociateCasesWithTours$Params, context?: HttpContext): Observable<TourListResponse> {
    return this.associateCasesWithTours$Response(params, context).pipe(
      map((r: StrictHttpResponse<TourListResponse>): TourListResponse => r.body)
    );
  }

  /** Path part for operation `duplicateTour()` */
  static readonly DuplicateTourPath = '/api/v1/tours/duplicate';

  /**
   * Request for duplicating tour.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `duplicateTour()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  duplicateTour$Response(params: DuplicateTour$Params, context?: HttpContext): Observable<StrictHttpResponse<Tour>> {
    return duplicateTour(this.http, this.rootUrl, params, context);
  }

  /**
   * Request for duplicating tour.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `duplicateTour$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  duplicateTour(params: DuplicateTour$Params, context?: HttpContext): Observable<Tour> {
    return this.duplicateTour$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tour>): Tour => r.body)
    );
  }

  /** Path part for operation `getTourByNaturalId()` */
  static readonly GetTourByNaturalIdPath = '/api/v1/tours/natural_id/{natural_id}';

  /**
   * Get resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTourByNaturalId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTourByNaturalId$Response(params: GetTourByNaturalId$Params, context?: HttpContext): Observable<StrictHttpResponse<Tour>> {
    return getTourByNaturalId(this.http, this.rootUrl, params, context);
  }

  /**
   * Get resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTourByNaturalId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTourByNaturalId(params: GetTourByNaturalId$Params, context?: HttpContext): Observable<Tour> {
    return this.getTourByNaturalId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tour>): Tour => r.body)
    );
  }

  /** Path part for operation `requestToursLocationUpdate()` */
  static readonly RequestToursLocationUpdatePath = '/api/v1/tours/send_update_location_message_to_vehicles';

  /**
   * Request location updates.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestToursLocationUpdate()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestToursLocationUpdate$Response(params?: RequestToursLocationUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return requestToursLocationUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Request location updates.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestToursLocationUpdate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestToursLocationUpdate(params?: RequestToursLocationUpdate$Params, context?: HttpContext): Observable<void> {
    return this.requestToursLocationUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createTour()` */
  static readonly CreateTourPath = '/api/v1/tours/start';

  /**
   * Create new resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTour()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTour$Response(params: CreateTour$Params, context?: HttpContext): Observable<StrictHttpResponse<Tour>> {
    return createTour(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTour$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTour(params: CreateTour$Params, context?: HttpContext): Observable<Tour> {
    return this.createTour$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tour>): Tour => r.body)
    );
  }

  /** Path part for operation `getStartedTourByVehicle()` */
  static readonly GetStartedTourByVehiclePath = '/api/v1/tours/started/vehicle/{id}';

  /**
   * Get resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStartedTourByVehicle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStartedTourByVehicle$Response(params: GetStartedTourByVehicle$Params, context?: HttpContext): Observable<StrictHttpResponse<Tour>> {
    return getStartedTourByVehicle(this.http, this.rootUrl, params, context);
  }

  /**
   * Get resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStartedTourByVehicle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStartedTourByVehicle(params: GetStartedTourByVehicle$Params, context?: HttpContext): Observable<Tour> {
    return this.getStartedTourByVehicle$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tour>): Tour => r.body)
    );
  }

  /** Path part for operation `getTour()` */
  static readonly GetTourPath = '/api/v1/tours/{id}';

  /**
   * Get resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTour()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTour$Response(params: GetTour$Params, context?: HttpContext): Observable<StrictHttpResponse<Tour>> {
    return getTour(this.http, this.rootUrl, params, context);
  }

  /**
   * Get resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTour$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTour(params: GetTour$Params, context?: HttpContext): Observable<Tour> {
    return this.getTour$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tour>): Tour => r.body)
    );
  }

  /** Path part for operation `finishTour()` */
  static readonly FinishTourPath = '/api/v1/tours/{id}/finish';

  /**
   * Update existing resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `finishTour()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  finishTour$Response(params: FinishTour$Params, context?: HttpContext): Observable<StrictHttpResponse<Tour>> {
    return finishTour(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `finishTour$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  finishTour(params: FinishTour$Params, context?: HttpContext): Observable<Tour> {
    return this.finishTour$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tour>): Tour => r.body)
    );
  }

  /** Path part for operation `requestTourLocationUpdate()` */
  static readonly RequestTourLocationUpdatePath = '/api/v1/tours/{id}/send_update_location_message_to_vehicles';

  /**
   * Request location update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestTourLocationUpdate()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestTourLocationUpdate$Response(params: RequestTourLocationUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return requestTourLocationUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Request location update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestTourLocationUpdate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestTourLocationUpdate(params: RequestTourLocationUpdate$Params, context?: HttpContext): Observable<void> {
    return this.requestTourLocationUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `startExistingTour()` */
  static readonly StartExistingTourPath = '/api/v1/tours/{id}/start';

  /**
   * Update existing scheduled tour.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startExistingTour()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  startExistingTour$Response(params: StartExistingTour$Params, context?: HttpContext): Observable<StrictHttpResponse<Tour>> {
    return startExistingTour(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing scheduled tour.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startExistingTour$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  startExistingTour(params: StartExistingTour$Params, context?: HttpContext): Observable<Tour> {
    return this.startExistingTour$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tour>): Tour => r.body)
    );
  }

  /** Path part for operation `updateStartedSubState()` */
  static readonly UpdateStartedSubStatePath = '/api/v1/tours/{id}/started_sub_state';

  /**
   * Update existing started tour.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateStartedSubState()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateStartedSubState$Response(params: UpdateStartedSubState$Params, context?: HttpContext): Observable<StrictHttpResponse<Tour>> {
    return updateStartedSubState(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing started tour.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateStartedSubState$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateStartedSubState(params: UpdateStartedSubState$Params, context?: HttpContext): Observable<Tour> {
    return this.updateStartedSubState$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tour>): Tour => r.body)
    );
  }

}
