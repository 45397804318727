/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GeoJsonIsochronesResponse } from '../../models/geo-json-isochrones-response';
import { IsochronesRequest } from '../../models/isochrones-request';

export interface GetDefaultIsochronesUsingPost$Params {

/**
 * Specifies the route profile.
 */
  profile: 'driving-car' | 'driving-hgv' | 'cycling-regular' | 'cycling-road' | 'cycling-mountain' | 'cycling-electric' | 'foot-walking' | 'foot-hiking' | 'wheelchair';
  
    /**
     * The request payload
     */
    body: IsochronesRequest
}

export function getDefaultIsochronesUsingPost(http: HttpClient, rootUrl: string, params: GetDefaultIsochronesUsingPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GeoJsonIsochronesResponse>> {
  const rb = new RequestBuilder(rootUrl, getDefaultIsochronesUsingPost.PATH, 'post');
  if (params) {
    rb.path('profile', params.profile, {});
    rb.body(params.body, 'application/geo+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/geo+json;charset=UTF-8', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GeoJsonIsochronesResponse>;
    })
  );
}

getDefaultIsochronesUsingPost.PATH = '/v2/isochrones/{profile}';
