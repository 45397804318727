/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ActiveVehicleListResponse } from '../models/active-vehicle-list-response';
import { getDirections } from '../fn/routing/get-directions';
import { GetDirections$Params } from '../fn/routing/get-directions';
import { getNearestActiveVehicles } from '../fn/routing/get-nearest-active-vehicles';
import { GetNearestActiveVehicles$Params } from '../fn/routing/get-nearest-active-vehicles';
import { getVroomCalculation } from '../fn/routing/get-vroom-calculation';
import { GetVroomCalculation$Params } from '../fn/routing/get-vroom-calculation';

@Injectable({ providedIn: 'root' })
export class RoutingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDirections()` */
  static readonly GetDirectionsPath = '/api/v1/routing/directions';

  /**
   * Get directions between multiple locations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDirections()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDirections$Response(params: GetDirections$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return getDirections(this.http, this.rootUrl, params, context);
  }

  /**
   * Get directions between multiple locations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDirections$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDirections(params: GetDirections$Params, context?: HttpContext): Observable<any> {
    return this.getDirections$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `getNearestActiveVehicles()` */
  static readonly GetNearestActiveVehiclesPath = '/api/v1/routing/nearest_active_vehicles';

  /**
   * Get nearest active vehicles for target location.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNearestActiveVehicles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNearestActiveVehicles$Response(params?: GetNearestActiveVehicles$Params, context?: HttpContext): Observable<StrictHttpResponse<ActiveVehicleListResponse>> {
    return getNearestActiveVehicles(this.http, this.rootUrl, params, context);
  }

  /**
   * Get nearest active vehicles for target location.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNearestActiveVehicles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNearestActiveVehicles(params?: GetNearestActiveVehicles$Params, context?: HttpContext): Observable<ActiveVehicleListResponse> {
    return this.getNearestActiveVehicles$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActiveVehicleListResponse>): ActiveVehicleListResponse => r.body)
    );
  }

  /** Path part for operation `getVroomCalculation()` */
  static readonly GetVroomCalculationPath = '/api/v1/routing/solver';

  /**
   * Get Vroom response for some calculation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVroomCalculation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getVroomCalculation$Response(params: GetVroomCalculation$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return getVroomCalculation(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Vroom response for some calculation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVroomCalculation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getVroomCalculation(params: GetVroomCalculation$Params, context?: HttpContext): Observable<any> {
    return this.getVroomCalculation$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
