import { ActivatedRouteSnapshot, RouteReuseStrategy, DetachedRouteHandle } from '@angular/router';

export class FuneralRouteReuseStrategy implements RouteReuseStrategy {
  private handlers: { [key: string]: DetachedRouteHandle } = {};

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    // Determines if this route (and its subtree) should be detached to be reused later
    const path = this.getRoutePath(route);
    if (!path.includes('/tours-planning')) {
      return false; // Do not reuse this specific path
    }
    return true;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    // Store the detached route
    this.handlers[this.getRouteKey(route)] = handle;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    // Determines if this route (and its subtree) should be reattached
    return !!this.handlers[this.getRouteKey(route)];
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    // Retrieve the previously stored route
    return this.handlers[this.getRouteKey(route)];
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // Determines if a route should be reused
    // const path = this.getRoutePath(future);
    // if (!path.includes('/tours-planning')) {
    //   return false; // Do not reuse this specific path
    // }
    return future.routeConfig === curr.routeConfig;
  }

  private getRouteKey(route: ActivatedRouteSnapshot): string {
    // Generate a unique key for each route
    return route.pathFromRoot
      .map((r) => r.url.map((segment) => segment.toString()).join('/'))
      .join('/');
  }

  private getRoutePath(route: ActivatedRouteSnapshot): string {
    // Construct the full path from the route
    return route.pathFromRoot
      .map((r) => r.url.map((segment) => segment.toString()).join('/'))
      .join('/');
  }
}
