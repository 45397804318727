import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AntiAuthGuard } from './general/guards/anti-auth.guard';
import { AuthGuard } from './general/guards/auth.guard';
import { TenantFoundGuard } from './general/guards/tenant-found.guard';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'fnrl',
    loadChildren: () =>
      import('./business-domains/funeral/funeral.module').then((m) => m.FuneralModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'rsa',
    loadChildren: () => import('./business-domains/rsa/rsa.module').then((m) => m.RsaModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./general/authentication/login/login.module').then((m) => m.LoginModule),
    canActivate: [AntiAuthGuard],
  },
  {
    path: 'signup-xyz',
    loadChildren: () =>
      import('./general/authentication/signup/signup.module').then((m) => m.SignupModule),
    canActivate: [AntiAuthGuard],
  },
  {
    path: 'tenant-not-found',
    loadChildren: () =>
      import('./general/authentication/tenant-not-found/tenant-not-found.module').then(
        (m) => m.TenantNotFoundModule
      ),
    canActivate: [TenantFoundGuard],
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./general/authentication/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
    canActivate: [AntiAuthGuard],
  },
  {
    path: 'change-password',
    loadChildren: () =>
      import('./general/authentication/change-password/change-password.module').then(
        (m) => m.ChangePasswordModule
      ),
    canActivate: [AntiAuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
