/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BrandListResponse } from '../models/brand-list-response';
import { getTenantBrands } from '../fn/brands/get-tenant-brands';
import { GetTenantBrands$Params } from '../fn/brands/get-tenant-brands';

@Injectable({ providedIn: 'root' })
export class BrandsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTenantBrands()` */
  static readonly GetTenantBrandsPath = '/api/v1/tenants/{id}/brands';

  /**
   * Get brands for tenant.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenantBrands()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantBrands$Response(params: GetTenantBrands$Params, context?: HttpContext): Observable<StrictHttpResponse<BrandListResponse>> {
    return getTenantBrands(this.http, this.rootUrl, params, context);
  }

  /**
   * Get brands for tenant.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenantBrands$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantBrands(params: GetTenantBrands$Params, context?: HttpContext): Observable<BrandListResponse> {
    return this.getTenantBrands$Response(params, context).pipe(
      map((r: StrictHttpResponse<BrandListResponse>): BrandListResponse => r.body)
    );
  }

}
