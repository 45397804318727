/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PaginatedTourListResponse } from '../../models/paginated-tour-list-response';

export interface GetRsaTours$Params {

/**
 * Pagination parameter. Skips page * size entries.
 */
  page?: number;

/**
 * Max number of results per page.
 */
  size?: number;

/**
 * Paramter to sort by as field(:(asc|desc))?. If missing default sort is used. field is the name in the db model not the db field name.
 */
  sort?: Array<string>;

/**
 * include only devices connected to specified branch.
 */
  branch_id?: string;

/**
 * include only tours connected to a device currently logged in. Ignored if loggedIn user is not DEVICE.
 */
  only_my_tours?: boolean;

/**
 * Parameter to exclude all cases where association is older than this parameter hours ago
 */
  cases_age_threshold_in_hours?: number;
}

export function getRsaTours(http: HttpClient, rootUrl: string, params?: GetRsaTours$Params, context?: HttpContext): Observable<StrictHttpResponse<PaginatedTourListResponse>> {
  const rb = new RequestBuilder(rootUrl, getRsaTours.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {"style":"form","explode":true});
    rb.query('size', params.size, {"style":"form","explode":true});
    rb.query('sort', params.sort, {"style":"form","explode":true});
    rb.query('branch_id', params.branch_id, {"style":"form","explode":true});
    rb.query('only_my_tours', params.only_my_tours, {"style":"form","explode":true});
    rb.query('cases_age_threshold_in_hours', params.cases_age_threshold_in_hours, {"style":"form","explode":true});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PaginatedTourListResponse>;
    })
  );
}

getRsaTours.PATH = '/api/v1/rsa/tours';
