import { Injectable, Signal, WritableSignal, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GrantsService {
  private _grants: WritableSignal<string[]> = signal([]);
  public grants: Signal<string[]> = this._grants.asReadonly();
  constructor() {}

  setGrants(token: string): void {
    const claims = atob(token.split('.')[1]);
    const claimsJson = JSON.parse(claims);
    const principal = atob(claimsJson.principal);
    const grants = JSON.parse(principal).grants;
    this._grants.set(grants);
  }

  hasTokenGrant(grant: string): boolean {
    return this.grants().includes(grant);
  }
}
