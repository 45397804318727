import { CasesActions, CasesActionTypes } from '../actions/cases.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Status } from '../../interfaces/status.enum';
import { Case, StoredAddress } from '@dispo-shared/open-api/models';

export interface CasesState extends EntityState<Case> {
  status: Status;
  error?: string | null;
  defaultLocations: StoredAddress[];
}

export const casesAdapter: EntityAdapter<Case> = createEntityAdapter<Case>();

export const casesInitialState: CasesState = {
  ...casesAdapter.getInitialState(),
  status: Status.Default,
  error: null,
  defaultLocations: [],
};

export function casesReducer(
  state: CasesState = casesInitialState,
  action: CasesActions
): CasesState {
  switch (action.type) {
    case CasesActionTypes.SetStatus: {
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error ? action.payload.error : null,
      };
    }

    case CasesActionTypes.AddCases: {
      return casesAdapter.addMany(action.payload.cases, state);
    }

    case CasesActionTypes.AddCase: {
      return casesAdapter.addOne(action.payload.case, state);
    }

    case CasesActionTypes.UpdateCase: {
      return casesAdapter.setOne(action.payload.case, state);
    }

    case CasesActionTypes.ClearCases: {
      return casesAdapter.removeAll(state);
    }

    case CasesActionTypes.ResetCases: {
      return Object.assign({}, casesInitialState);
    }

    case CasesActionTypes.SetDefaultLocations: {
      return {
        ...state,
        defaultLocations: action.payload.locations,
      };
    }

    default: {
      return state;
    }
  }
}
