import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { TenantConfigService } from '../services/tenant-config.service';

@Injectable({
  providedIn: 'root',
})
class PermissionsService {
  private auth = inject(AuthService);
  private tenantService = inject(TenantConfigService);
  private router = inject(Router);

  canActivate(): boolean {
    if (this.auth.getAccessToken() && this.auth.getRefreshToken()) {
      const route = this.tenantService.defaultRoute();
      this.router.navigateByUrl(route);
      return false;
    }
    return true;
  }
}

export const AntiAuthGuard: CanActivateFn = () => {
  return inject(PermissionsService).canActivate();
};
