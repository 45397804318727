import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[toastr-component]',
  templateUrl: './custom-toasts.component.html',
  styleUrls: ['./custom-toasts.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class CustomToastsComponent extends Toast {
  protected declare toastrService: ToastrService;
  declare toastPackage: ToastPackage;
  private router = inject(Router);

  constructor() {
    const toastrService = inject(ToastrService);
    const toastPackage = inject(ToastPackage);

    super(toastrService, toastPackage);
    this.toastrService = toastrService;
    this.toastPackage = toastPackage;
  }

  navigateTo(url: string, id: string | number): void {
    if (!this.router.url.startsWith(`${url}${id}`)) {
      this.router.navigate([`${url}${id}`]);
    }
    this.remove();
  }
}
