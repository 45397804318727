/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CalendarEventExclusion } from '../models/calendar-event-exclusion';
import { CalendarEventExclusionListResponse } from '../models/calendar-event-exclusion-list-response';
import { createEventExclusions } from '../fn/calendar-recursion-exclusions/create-event-exclusions';
import { CreateEventExclusions$Params } from '../fn/calendar-recursion-exclusions/create-event-exclusions';
import { deleteEventExclusion } from '../fn/calendar-recursion-exclusions/delete-event-exclusion';
import { DeleteEventExclusion$Params } from '../fn/calendar-recursion-exclusions/delete-event-exclusion';
import { getEventExclusionById } from '../fn/calendar-recursion-exclusions/get-event-exclusion-by-id';
import { GetEventExclusionById$Params } from '../fn/calendar-recursion-exclusions/get-event-exclusion-by-id';
import { getEventExclusions } from '../fn/calendar-recursion-exclusions/get-event-exclusions';
import { GetEventExclusions$Params } from '../fn/calendar-recursion-exclusions/get-event-exclusions';

@Injectable({ providedIn: 'root' })
export class CalendarRecursionExclusionsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getEventExclusions()` */
  static readonly GetEventExclusionsPath = '/api/v1/calendarEvents/{eventId}/exclusions';

  /**
   * Get event exclusions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEventExclusions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventExclusions$Response(params: GetEventExclusions$Params, context?: HttpContext): Observable<StrictHttpResponse<CalendarEventExclusionListResponse>> {
    return getEventExclusions(this.http, this.rootUrl, params, context);
  }

  /**
   * Get event exclusions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEventExclusions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventExclusions(params: GetEventExclusions$Params, context?: HttpContext): Observable<CalendarEventExclusionListResponse> {
    return this.getEventExclusions$Response(params, context).pipe(
      map((r: StrictHttpResponse<CalendarEventExclusionListResponse>): CalendarEventExclusionListResponse => r.body)
    );
  }

  /** Path part for operation `createEventExclusions()` */
  static readonly CreateEventExclusionsPath = '/api/v1/calendarEvents/{eventId}/exclusions';

  /**
   * Create event exclusions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createEventExclusions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createEventExclusions$Response(params: CreateEventExclusions$Params, context?: HttpContext): Observable<StrictHttpResponse<CalendarEventExclusion>> {
    return createEventExclusions(this.http, this.rootUrl, params, context);
  }

  /**
   * Create event exclusions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createEventExclusions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createEventExclusions(params: CreateEventExclusions$Params, context?: HttpContext): Observable<CalendarEventExclusion> {
    return this.createEventExclusions$Response(params, context).pipe(
      map((r: StrictHttpResponse<CalendarEventExclusion>): CalendarEventExclusion => r.body)
    );
  }

  /** Path part for operation `getEventExclusionById()` */
  static readonly GetEventExclusionByIdPath = '/api/v1/calendarEvents/{eventId}/exclusions/{id}';

  /**
   * Get event exclusion.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEventExclusionById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventExclusionById$Response(params: GetEventExclusionById$Params, context?: HttpContext): Observable<StrictHttpResponse<CalendarEventExclusion>> {
    return getEventExclusionById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get event exclusion.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEventExclusionById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventExclusionById(params: GetEventExclusionById$Params, context?: HttpContext): Observable<CalendarEventExclusion> {
    return this.getEventExclusionById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CalendarEventExclusion>): CalendarEventExclusion => r.body)
    );
  }

  /** Path part for operation `deleteEventExclusion()` */
  static readonly DeleteEventExclusionPath = '/api/v1/calendarEvents/{eventId}/exclusions/{id}';

  /**
   * Delete event exclusion.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEventExclusion()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEventExclusion$Response(params: DeleteEventExclusion$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteEventExclusion(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete event exclusion.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteEventExclusion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEventExclusion(params: DeleteEventExclusion$Params, context?: HttpContext): Observable<void> {
    return this.deleteEventExclusion$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
