/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createTenant } from '../fn/tenants/create-tenant';
import { CreateTenant$Params } from '../fn/tenants/create-tenant';
import { createTenantMasterData } from '../fn/tenants/create-tenant-master-data';
import { CreateTenantMasterData$Params } from '../fn/tenants/create-tenant-master-data';
import { getTenant } from '../fn/tenants/get-tenant';
import { GetTenant$Params } from '../fn/tenants/get-tenant';
import { getTenantByDomain } from '../fn/tenants/get-tenant-by-domain';
import { GetTenantByDomain$Params } from '../fn/tenants/get-tenant-by-domain';
import { getTenantByShortCode } from '../fn/tenants/get-tenant-by-short-code';
import { GetTenantByShortCode$Params } from '../fn/tenants/get-tenant-by-short-code';
import { getTenantFrontendConfigByShortCode } from '../fn/tenants/get-tenant-frontend-config-by-short-code';
import { GetTenantFrontendConfigByShortCode$Params } from '../fn/tenants/get-tenant-frontend-config-by-short-code';
import { getTenantKeyByShortCode } from '../fn/tenants/get-tenant-key-by-short-code';
import { GetTenantKeyByShortCode$Params } from '../fn/tenants/get-tenant-key-by-short-code';
import { getTenantMasterData } from '../fn/tenants/get-tenant-master-data';
import { GetTenantMasterData$Params } from '../fn/tenants/get-tenant-master-data';
import { getTenants } from '../fn/tenants/get-tenants';
import { GetTenants$Params } from '../fn/tenants/get-tenants';
import { Tenant } from '../models/tenant';
import { TenantAndConfigs } from '../models/tenant-and-configs';
import { TenantFrontendConfig } from '../models/tenant-frontend-config';
import { TenantKeyResponse } from '../models/tenant-key-response';
import { TenantListResponse } from '../models/tenant-list-response';
import { TenantMasterData } from '../models/tenant-master-data';
import { TenantMasterDataListResponse } from '../models/tenant-master-data-list-response';
import { togglePhoneNumberLogin } from '../fn/tenants/toggle-phone-number-login';
import { TogglePhoneNumberLogin$Params } from '../fn/tenants/toggle-phone-number-login';
import { updateFrontendConfig } from '../fn/tenants/update-frontend-config';
import { UpdateFrontendConfig$Params } from '../fn/tenants/update-frontend-config';
import { updateTenant } from '../fn/tenants/update-tenant';
import { UpdateTenant$Params } from '../fn/tenants/update-tenant';
import { updateTenantConfigs } from '../fn/tenants/update-tenant-configs';
import { UpdateTenantConfigs$Params } from '../fn/tenants/update-tenant-configs';
import { updateTenantLogoDefault } from '../fn/tenants/update-tenant-logo-default';
import { UpdateTenantLogoDefault$Params } from '../fn/tenants/update-tenant-logo-default';
import { updateTenantMasterData } from '../fn/tenants/update-tenant-master-data';
import { UpdateTenantMasterData$Params } from '../fn/tenants/update-tenant-master-data';

@Injectable({ providedIn: 'root' })
export class TenantsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTenants()` */
  static readonly GetTenantsPath = '/api/v1/tenants';

  /**
   * Get paginated list of this resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenants()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenants$Response(params?: GetTenants$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantListResponse>> {
    return getTenants(this.http, this.rootUrl, params, context);
  }

  /**
   * Get paginated list of this resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenants$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenants(params?: GetTenants$Params, context?: HttpContext): Observable<TenantListResponse> {
    return this.getTenants$Response(params, context).pipe(
      map((r: StrictHttpResponse<TenantListResponse>): TenantListResponse => r.body)
    );
  }

  /** Path part for operation `createTenant()` */
  static readonly CreateTenantPath = '/api/v1/tenants';

  /**
   * Create new resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTenant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTenant$Response(params: CreateTenant$Params, context?: HttpContext): Observable<StrictHttpResponse<Tenant>> {
    return createTenant(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTenant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTenant(params: CreateTenant$Params, context?: HttpContext): Observable<Tenant> {
    return this.createTenant$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tenant>): Tenant => r.body)
    );
  }

  /** Path part for operation `getTenantByDomain()` */
  static readonly GetTenantByDomainPath = '/api/v1/tenants/config';

  /**
   * Get tenant by domain.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenantByDomain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantByDomain$Response(params: GetTenantByDomain$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantAndConfigs>> {
    return getTenantByDomain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get tenant by domain.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenantByDomain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantByDomain(params: GetTenantByDomain$Params, context?: HttpContext): Observable<TenantAndConfigs> {
    return this.getTenantByDomain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TenantAndConfigs>): TenantAndConfigs => r.body)
    );
  }

  /** Path part for operation `getTenantByShortCode()` */
  static readonly GetTenantByShortCodePath = '/api/v1/tenants/config/{short_code}';

  /**
   * Get tenant and config by short code.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenantByShortCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantByShortCode$Response(params: GetTenantByShortCode$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantAndConfigs>> {
    return getTenantByShortCode(this.http, this.rootUrl, params, context);
  }

  /**
   * Get tenant and config by short code.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenantByShortCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantByShortCode(params: GetTenantByShortCode$Params, context?: HttpContext): Observable<TenantAndConfigs> {
    return this.getTenantByShortCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<TenantAndConfigs>): TenantAndConfigs => r.body)
    );
  }

  /** Path part for operation `getTenantFrontendConfigByShortCode()` */
  static readonly GetTenantFrontendConfigByShortCodePath = '/api/v1/tenants/config/{short_code}/frontend';

  /**
   * Get frontend config of the tenant based on the shortcode.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenantFrontendConfigByShortCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantFrontendConfigByShortCode$Response(params: GetTenantFrontendConfigByShortCode$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantFrontendConfig>> {
    return getTenantFrontendConfigByShortCode(this.http, this.rootUrl, params, context);
  }

  /**
   * Get frontend config of the tenant based on the shortcode.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenantFrontendConfigByShortCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantFrontendConfigByShortCode(params: GetTenantFrontendConfigByShortCode$Params, context?: HttpContext): Observable<TenantFrontendConfig> {
    return this.getTenantFrontendConfigByShortCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<TenantFrontendConfig>): TenantFrontendConfig => r.body)
    );
  }

  /** Path part for operation `updateFrontendConfig()` */
  static readonly UpdateFrontendConfigPath = '/api/v1/tenants/config/{short_code}/frontend';

  /**
   * Update frontend config for tenant.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFrontendConfig()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFrontendConfig$Response(params: UpdateFrontendConfig$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantFrontendConfig>> {
    return updateFrontendConfig(this.http, this.rootUrl, params, context);
  }

  /**
   * Update frontend config for tenant.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateFrontendConfig$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFrontendConfig(params: UpdateFrontendConfig$Params, context?: HttpContext): Observable<TenantFrontendConfig> {
    return this.updateFrontendConfig$Response(params, context).pipe(
      map((r: StrictHttpResponse<TenantFrontendConfig>): TenantFrontendConfig => r.body)
    );
  }

  /** Path part for operation `getTenantKeyByShortCode()` */
  static readonly GetTenantKeyByShortCodePath = '/api/v1/tenants/config/{short_code}/key';

  /**
   * Get tenantKey by short code.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenantKeyByShortCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantKeyByShortCode$Response(params: GetTenantKeyByShortCode$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantKeyResponse>> {
    return getTenantKeyByShortCode(this.http, this.rootUrl, params, context);
  }

  /**
   * Get tenantKey by short code.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenantKeyByShortCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantKeyByShortCode(params: GetTenantKeyByShortCode$Params, context?: HttpContext): Observable<TenantKeyResponse> {
    return this.getTenantKeyByShortCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<TenantKeyResponse>): TenantKeyResponse => r.body)
    );
  }

  /** Path part for operation `getTenant()` */
  static readonly GetTenantPath = '/api/v1/tenants/{id}';

  /**
   * Get resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenant()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenant$Response(params: GetTenant$Params, context?: HttpContext): Observable<StrictHttpResponse<Tenant>> {
    return getTenant(this.http, this.rootUrl, params, context);
  }

  /**
   * Get resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenant(params: GetTenant$Params, context?: HttpContext): Observable<Tenant> {
    return this.getTenant$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tenant>): Tenant => r.body)
    );
  }

  /** Path part for operation `updateTenantConfigs()` */
  static readonly UpdateTenantConfigsPath = '/api/v1/tenants/{id}';

  /**
   * Update existing resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTenantConfigs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTenantConfigs$Response(params: UpdateTenantConfigs$Params, context?: HttpContext): Observable<StrictHttpResponse<Tenant>> {
    return updateTenantConfigs(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTenantConfigs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTenantConfigs(params: UpdateTenantConfigs$Params, context?: HttpContext): Observable<Tenant> {
    return this.updateTenantConfigs$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tenant>): Tenant => r.body)
    );
  }

  /** Path part for operation `updateTenant()` */
  static readonly UpdateTenantPath = '/api/v1/tenants/{id}';

  /**
   * Update an existing tenant.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTenant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTenant$Response(params: UpdateTenant$Params, context?: HttpContext): Observable<StrictHttpResponse<Tenant>> {
    return updateTenant(this.http, this.rootUrl, params, context);
  }

  /**
   * Update an existing tenant.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTenant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTenant(params: UpdateTenant$Params, context?: HttpContext): Observable<Tenant> {
    return this.updateTenant$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tenant>): Tenant => r.body)
    );
  }

  /** Path part for operation `updateTenantLogoDefault()` */
  static readonly UpdateTenantLogoDefaultPath = '/api/v1/tenants/{id}/logos';

  /**
   * Update default tenant logo.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTenantLogoDefault()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTenantLogoDefault$Response(params: UpdateTenantLogoDefault$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return updateTenantLogoDefault(this.http, this.rootUrl, params, context);
  }

  /**
   * Update default tenant logo.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTenantLogoDefault$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTenantLogoDefault(params: UpdateTenantLogoDefault$Params, context?: HttpContext): Observable<any> {
    return this.updateTenantLogoDefault$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `togglePhoneNumberLogin()` */
  static readonly TogglePhoneNumberLoginPath = '/api/v1/tenants/{id}/phonenumber_signin';

  /**
   * Make phone number login enabled or disabled.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `togglePhoneNumberLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  togglePhoneNumberLogin$Response(params: TogglePhoneNumberLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return togglePhoneNumberLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * Make phone number login enabled or disabled.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `togglePhoneNumberLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  togglePhoneNumberLogin(params: TogglePhoneNumberLogin$Params, context?: HttpContext): Observable<any> {
    return this.togglePhoneNumberLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `getTenantMasterData()` */
  static readonly GetTenantMasterDataPath = '/api/v1/tenants/{tenant_short_code}/masterdata';

  /**
   * Get list of static data of the tenant.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenantMasterData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantMasterData$Response(params: GetTenantMasterData$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantMasterDataListResponse>> {
    return getTenantMasterData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of static data of the tenant.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenantMasterData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantMasterData(params: GetTenantMasterData$Params, context?: HttpContext): Observable<TenantMasterDataListResponse> {
    return this.getTenantMasterData$Response(params, context).pipe(
      map((r: StrictHttpResponse<TenantMasterDataListResponse>): TenantMasterDataListResponse => r.body)
    );
  }

  /** Path part for operation `createTenantMasterData()` */
  static readonly CreateTenantMasterDataPath = '/api/v1/tenants/{tenant_short_code}/masterdata';

  /**
   * Post a static data of the tenant.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTenantMasterData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTenantMasterData$Response(params: CreateTenantMasterData$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantMasterData>> {
    return createTenantMasterData(this.http, this.rootUrl, params, context);
  }

  /**
   * Post a static data of the tenant.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTenantMasterData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTenantMasterData(params: CreateTenantMasterData$Params, context?: HttpContext): Observable<TenantMasterData> {
    return this.createTenantMasterData$Response(params, context).pipe(
      map((r: StrictHttpResponse<TenantMasterData>): TenantMasterData => r.body)
    );
  }

  /** Path part for operation `updateTenantMasterData()` */
  static readonly UpdateTenantMasterDataPath = '/api/v1/tenants/{tenant_short_code}/masterdata/{id}';

  /**
   * Update a static data of the tenant.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTenantMasterData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTenantMasterData$Response(params: UpdateTenantMasterData$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantMasterData>> {
    return updateTenantMasterData(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a static data of the tenant.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTenantMasterData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTenantMasterData(params: UpdateTenantMasterData$Params, context?: HttpContext): Observable<TenantMasterData> {
    return this.updateTenantMasterData$Response(params, context).pipe(
      map((r: StrictHttpResponse<TenantMasterData>): TenantMasterData => r.body)
    );
  }

}
