import { UsersActions, UsersActionTypes } from '../actions/users.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Status } from '../../interfaces/status.enum';
import { ErrorResponse } from '@dispo-web/app/general/interfaces/error.interface';
import { User } from '@dispo-shared/open-api/models';

export interface UsersState extends EntityState<User> {
  status: Status;
  error: ErrorResponse | null;
}

export const usersAdapter: EntityAdapter<User> = createEntityAdapter<User>();

export const usersInitialState: UsersState = {
  ...usersAdapter.getInitialState(),
  status: Status.Default,
  error: null,
};

export function usersReducer(
  state: UsersState = usersInitialState,
  action: UsersActions
): UsersState {
  switch (action.type) {
    case UsersActionTypes.SetStatus: {
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error || null,
      };
    }

    case UsersActionTypes.AddUsers: {
      return usersAdapter.addMany(action.payload.users, state);
    }

    case UsersActionTypes.AddUser: {
      return usersAdapter.addOne(action.payload.user, state);
    }

    case UsersActionTypes.UpdateUser: {
      return usersAdapter.setOne(action.payload.user, state);
    }

    case UsersActionTypes.SetUsers: {
      return usersAdapter.setAll(action.payload.users, state);
    }

    case UsersActionTypes.ClearUsers: {
      return usersAdapter.removeAll(state);
    }

    case UsersActionTypes.ResetUsers: {
      return Object.assign({}, usersInitialState);
    }

    default: {
      return state;
    }
  }
}
