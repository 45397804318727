import { Action } from '@ngrx/store';
import { Role, Skill } from '@dispo-shared/open-api/models';
import { Status } from '../../interfaces/status.enum';

export enum RolesActionTypes {
  SetStatus = '[Roles] Set status',
  SetRoles = '[Roles] Set roles',
  ResetRoles = '[Roles] Reset roles',
  SetSkills = '[Roles] Set skills',
  SetSkillsStatus = '[Roles] Set skills status',
}

export class SetStatus implements Action {
  public readonly type = RolesActionTypes.SetStatus;
  constructor(public payload: { status: Status; error?: string }) {}
}

export class SetRoles implements Action {
  public readonly type = RolesActionTypes.SetRoles;
  constructor(public payload: { roles: Role[] }) {}
}

export class ResetRoles implements Action {
  public readonly type = RolesActionTypes.ResetRoles;
}
export class SetSkills implements Action {
  public readonly type = RolesActionTypes.SetSkills;
  constructor(public payload: { skills: Skill[] }) {}
}

export class SetSkillsStatus implements Action {
  public readonly type = RolesActionTypes.SetSkillsStatus;
  constructor(public payload: { status: Status; error?: string }) {}
}

export type RolesActions = SetStatus | SetRoles | ResetRoles | SetSkills | SetSkillsStatus;
