/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createTodoTemplate } from '../fn/todos-templates/create-todo-template';
import { CreateTodoTemplate$Params } from '../fn/todos-templates/create-todo-template';
import { deleteTodoTemplate } from '../fn/todos-templates/delete-todo-template';
import { DeleteTodoTemplate$Params } from '../fn/todos-templates/delete-todo-template';
import { getTodosTemplates } from '../fn/todos-templates/get-todos-templates';
import { GetTodosTemplates$Params } from '../fn/todos-templates/get-todos-templates';
import { getTodoTemplate } from '../fn/todos-templates/get-todo-template';
import { GetTodoTemplate$Params } from '../fn/todos-templates/get-todo-template';
import { TodoTemplate } from '../models/todo-template';
import { TodoTemplatesListResponse } from '../models/todo-templates-list-response';
import { updateTodoTemplate } from '../fn/todos-templates/update-todo-template';
import { UpdateTodoTemplate$Params } from '../fn/todos-templates/update-todo-template';

@Injectable({ providedIn: 'root' })
export class TodosTemplatesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTodosTemplates()` */
  static readonly GetTodosTemplatesPath = '/api/v1/todo_templates';

  /**
   * get list of todoTemplates.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTodosTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTodosTemplates$Response(params?: GetTodosTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<TodoTemplatesListResponse>> {
    return getTodosTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * get list of todoTemplates.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTodosTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTodosTemplates(params?: GetTodosTemplates$Params, context?: HttpContext): Observable<TodoTemplatesListResponse> {
    return this.getTodosTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<TodoTemplatesListResponse>): TodoTemplatesListResponse => r.body)
    );
  }

  /** Path part for operation `createTodoTemplate()` */
  static readonly CreateTodoTemplatePath = '/api/v1/todo_templates';

  /**
   * create new todo template.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTodoTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTodoTemplate$Response(params: CreateTodoTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<TodoTemplate>> {
    return createTodoTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * create new todo template.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTodoTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTodoTemplate(params: CreateTodoTemplate$Params, context?: HttpContext): Observable<TodoTemplate> {
    return this.createTodoTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TodoTemplate>): TodoTemplate => r.body)
    );
  }

  /** Path part for operation `getTodoTemplate()` */
  static readonly GetTodoTemplatePath = '/api/v1/todo_templates/{id}';

  /**
   * Get single todo template by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTodoTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTodoTemplate$Response(params: GetTodoTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<TodoTemplate>> {
    return getTodoTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Get single todo template by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTodoTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTodoTemplate(params: GetTodoTemplate$Params, context?: HttpContext): Observable<TodoTemplate> {
    return this.getTodoTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TodoTemplate>): TodoTemplate => r.body)
    );
  }

  /** Path part for operation `updateTodoTemplate()` */
  static readonly UpdateTodoTemplatePath = '/api/v1/todo_templates/{id}';

  /**
   * Update existing todo template.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTodoTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTodoTemplate$Response(params: UpdateTodoTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<TodoTemplate>> {
    return updateTodoTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing todo template.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTodoTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTodoTemplate(params: UpdateTodoTemplate$Params, context?: HttpContext): Observable<TodoTemplate> {
    return this.updateTodoTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TodoTemplate>): TodoTemplate => r.body)
    );
  }

  /** Path part for operation `deleteTodoTemplate()` */
  static readonly DeleteTodoTemplatePath = '/api/v1/todo_templates/{id}';

  /**
   * Delete an existing todo template.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTodoTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTodoTemplate$Response(params: DeleteTodoTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTodoTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete an existing todo template.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTodoTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTodoTemplate(params: DeleteTodoTemplate$Params, context?: HttpContext): Observable<void> {
    return this.deleteTodoTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
