/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { UserListResponse } from '../../models/user-list-response';

export interface GetUsers$Params {

/**
 * Pagination parameter. Skips page * size entries.
 */
  page?: number;

/**
 * Max number of results per page.
 */
  size?: number;

/**
 * Paramter to sort by as field(:(asc|desc))?. If missing default sort is used. field is the name in the db model not the db field name.
 */
  sort?: Array<string>;

/**
 * Restrict result to this role
 */
  role?: string;

/**
 * Filter users that based on active boolean
 */
  active?: boolean;

/**
 * Only get users having roles related to required branch access scope
 */
  associated_roles_branch_access_scope?: 'ALL' | 'BRANCH_COLLECTION' | 'BRANCH_SINGLE';

/**
 * Only get users having roles related to required branch group id
 */
  branch_group_id?: string;
}

export function getUsers(http: HttpClient, rootUrl: string, params?: GetUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<UserListResponse>> {
  const rb = new RequestBuilder(rootUrl, getUsers.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {"style":"form","explode":true});
    rb.query('size', params.size, {"style":"form","explode":true});
    rb.query('sort', params.sort, {"style":"form","explode":true});
    rb.query('role', params.role, {"style":"form","explode":true});
    rb.query('active', params.active, {"style":"form","explode":true});
    rb.query('associated_roles_branch_access_scope', params.associated_roles_branch_access_scope, {"style":"form","explode":true});
    rb.query('branch_group_id', params.branch_group_id, {"style":"form","explode":true});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<UserListResponse>;
    })
  );
}

getUsers.PATH = '/api/v1/users';
