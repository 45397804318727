/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { associateDevice } from '../fn/vehicles/associate-device';
import { AssociateDevice$Params } from '../fn/vehicles/associate-device';
import { createVehicle } from '../fn/vehicles/create-vehicle';
import { CreateVehicle$Params } from '../fn/vehicles/create-vehicle';
import { getVehicle } from '../fn/vehicles/get-vehicle';
import { GetVehicle$Params } from '../fn/vehicles/get-vehicle';
import { getVehicles } from '../fn/vehicles/get-vehicles';
import { GetVehicles$Params } from '../fn/vehicles/get-vehicles';
import { updateVehicle } from '../fn/vehicles/update-vehicle';
import { UpdateVehicle$Params } from '../fn/vehicles/update-vehicle';
import { Vehicle } from '../models/vehicle';
import { VehicleListResponse } from '../models/vehicle-list-response';

@Injectable({ providedIn: 'root' })
export class VehiclesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getVehicles()` */
  static readonly GetVehiclesPath = '/api/v1/vehicles';

  /**
   * Get paginated list of this resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehicles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicles$Response(params?: GetVehicles$Params, context?: HttpContext): Observable<StrictHttpResponse<VehicleListResponse>> {
    return getVehicles(this.http, this.rootUrl, params, context);
  }

  /**
   * Get paginated list of this resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVehicles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicles(params?: GetVehicles$Params, context?: HttpContext): Observable<VehicleListResponse> {
    return this.getVehicles$Response(params, context).pipe(
      map((r: StrictHttpResponse<VehicleListResponse>): VehicleListResponse => r.body)
    );
  }

  /** Path part for operation `createVehicle()` */
  static readonly CreateVehiclePath = '/api/v1/vehicles';

  /**
   * Create new resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createVehicle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createVehicle$Response(params: CreateVehicle$Params, context?: HttpContext): Observable<StrictHttpResponse<Vehicle>> {
    return createVehicle(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createVehicle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createVehicle(params: CreateVehicle$Params, context?: HttpContext): Observable<Vehicle> {
    return this.createVehicle$Response(params, context).pipe(
      map((r: StrictHttpResponse<Vehicle>): Vehicle => r.body)
    );
  }

  /** Path part for operation `getVehicle()` */
  static readonly GetVehiclePath = '/api/v1/vehicles/{id}';

  /**
   * Get resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehicle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicle$Response(params: GetVehicle$Params, context?: HttpContext): Observable<StrictHttpResponse<Vehicle>> {
    return getVehicle(this.http, this.rootUrl, params, context);
  }

  /**
   * Get resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVehicle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicle(params: GetVehicle$Params, context?: HttpContext): Observable<Vehicle> {
    return this.getVehicle$Response(params, context).pipe(
      map((r: StrictHttpResponse<Vehicle>): Vehicle => r.body)
    );
  }

  /** Path part for operation `updateVehicle()` */
  static readonly UpdateVehiclePath = '/api/v1/vehicles/{id}';

  /**
   * Update existing resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateVehicle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVehicle$Response(params: UpdateVehicle$Params, context?: HttpContext): Observable<StrictHttpResponse<Vehicle>> {
    return updateVehicle(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateVehicle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVehicle(params: UpdateVehicle$Params, context?: HttpContext): Observable<Vehicle> {
    return this.updateVehicle$Response(params, context).pipe(
      map((r: StrictHttpResponse<Vehicle>): Vehicle => r.body)
    );
  }

  /** Path part for operation `associateDevice()` */
  static readonly AssociateDevicePath = '/api/v1/vehicles/{id}/associate/device';

  /**
   * Create new device association.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `associateDevice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  associateDevice$Response(params: AssociateDevice$Params, context?: HttpContext): Observable<StrictHttpResponse<Vehicle>> {
    return associateDevice(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new device association.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `associateDevice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  associateDevice(params: AssociateDevice$Params, context?: HttpContext): Observable<Vehicle> {
    return this.associateDevice$Response(params, context).pipe(
      map((r: StrictHttpResponse<Vehicle>): Vehicle => r.body)
    );
  }

}
