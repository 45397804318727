import { Action } from '@ngrx/store';
import { Status } from '../../interfaces/status.enum';

export enum VehicleSidebarActionTypes {
  SetManageDialogOpenStatus = '[VehicleSidebar] Set manage dialog open status',
  SetManageDialogRequestStatus = '[VehicleSidebar] Set manage dialog API request status',
  SetAssociationSidebarOpenStatus = '[VehicleSidebar] Set association dialog open status',
  SetAssociationSidebarRequestStatus = '[VehicleSidebar] Set association dialog API request status',
}

export class SetManageDialogOpenStatus implements Action {
  public readonly type = VehicleSidebarActionTypes.SetManageDialogOpenStatus;
  constructor(public payload: { open: boolean }) {}
}

export class SetManageDialogRequestStatus implements Action {
  public readonly type = VehicleSidebarActionTypes.SetManageDialogRequestStatus;
  constructor(public payload: { status: Status; error?: string }) {}
}

export class SetAssociationSidebarOpenStatus implements Action {
  public readonly type = VehicleSidebarActionTypes.SetAssociationSidebarOpenStatus;
  constructor(public payload: { open: boolean }) {}
}

export class SetAssociationSidebarRequestStatus implements Action {
  public readonly type = VehicleSidebarActionTypes.SetAssociationSidebarRequestStatus;
  constructor(public payload: { status: Status; error?: string }) {}
}

export type VehicleSidebarActions =
  | SetManageDialogOpenStatus
  | SetManageDialogRequestStatus
  | SetAssociationSidebarOpenStatus
  | SetAssociationSidebarRequestStatus;
