/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { forwardSearch } from '../fn/geocoding/forward-search';
import { ForwardSearch$Params } from '../fn/geocoding/forward-search';
import { GeocodingAddress } from '../models/geocoding-address';
import { GeocodingAddressListResponse } from '../models/geocoding-address-list-response';
import { reverseSearch } from '../fn/geocoding/reverse-search';
import { ReverseSearch$Params } from '../fn/geocoding/reverse-search';
import { structuredSearch } from '../fn/geocoding/structured-search';
import { StructuredSearch$Params } from '../fn/geocoding/structured-search';
import { SuggestedAddressListResponse } from '../models/suggested-address-list-response';

@Injectable({ providedIn: 'root' })
export class GeocodingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `forwardSearch()` */
  static readonly ForwardSearchPath = '/api/v1/geocoding/addresses/forward_search';

  /**
   * request for geo forward search.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `forwardSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  forwardSearch$Response(params: ForwardSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<SuggestedAddressListResponse>> {
    return forwardSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * request for geo forward search.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `forwardSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  forwardSearch(params: ForwardSearch$Params, context?: HttpContext): Observable<SuggestedAddressListResponse> {
    return this.forwardSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuggestedAddressListResponse>): SuggestedAddressListResponse => r.body)
    );
  }

  /** Path part for operation `reverseSearch()` */
  static readonly ReverseSearchPath = '/api/v1/geocoding/addresses/reverse_search';

  /**
   * request for geo reverse search.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reverseSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  reverseSearch$Response(params: ReverseSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<GeocodingAddress>> {
    return reverseSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * request for geo reverse search.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reverseSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reverseSearch(params: ReverseSearch$Params, context?: HttpContext): Observable<GeocodingAddress> {
    return this.reverseSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<GeocodingAddress>): GeocodingAddress => r.body)
    );
  }

  /** Path part for operation `structuredSearch()` */
  static readonly StructuredSearchPath = '/api/v1/geocoding/addresses/search/structured';

  /**
   * request for geo structured search.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structuredSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  structuredSearch$Response(params?: StructuredSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<GeocodingAddressListResponse>> {
    return structuredSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * request for geo structured search.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `structuredSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  structuredSearch(params?: StructuredSearch$Params, context?: HttpContext): Observable<GeocodingAddressListResponse> {
    return this.structuredSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<GeocodingAddressListResponse>): GeocodingAddressListResponse => r.body)
    );
  }

}
