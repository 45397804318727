import { VehiclesActions, VehiclesActionTypes } from '../actions/vehicles.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Status } from '../../interfaces/status.enum';
import { ErrorResponse } from '@dispo-web/app/general/interfaces/error.interface';
import { Vehicle, VehicleType } from '@dispo-shared/open-api/models';

export interface VehiclesState extends EntityState<Vehicle> {
  status: Status;
  vehicleTypes: VehicleType[];
  error: ErrorResponse | null;
}

export const vehiclesAdapter: EntityAdapter<Vehicle> = createEntityAdapter<Vehicle>();

export const vehiclesInitialState: VehiclesState = {
  ...vehiclesAdapter.getInitialState(),
  status: Status.Default,
  vehicleTypes: [{ name: '-' }],
  error: null,
};

export function vehiclesReducer(
  state: VehiclesState = vehiclesInitialState,
  action: VehiclesActions
): VehiclesState {
  switch (action.type) {
    case VehiclesActionTypes.SetStatus: {
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error || null,
      };
    }

    case VehiclesActionTypes.AddVehicles: {
      return vehiclesAdapter.addMany(action.payload.vehicles, state);
    }

    case VehiclesActionTypes.AddVehicle: {
      return vehiclesAdapter.addOne(action.payload.vehicle, state);
    }

    case VehiclesActionTypes.UpdateVehicle: {
      return vehiclesAdapter.setOne(action.payload.vehicle, state);
    }

    case VehiclesActionTypes.UpdateVehicleTour: {
      const tourUpdateMessage = action.payload.tour;
      const editedVehicles: Vehicle[] = [];
      tourUpdateMessage.tour?.vehicle_device_associations?.forEach((vehicle_device_association) => {
        const entVehicle = vehicle_device_association.vehicle.id
          ? state.entities[vehicle_device_association.vehicle.id]
          : null;

        if (entVehicle) {
          let newVehicle;
          if (tourUpdateMessage.tour?.ended_at) {
            newVehicle = { ...entVehicle };
            delete newVehicle.current_tour_natural_id;
            delete newVehicle.current_tour_status;
          } else {
            newVehicle = {
              ...entVehicle,
              current_tour_natural_id: tourUpdateMessage.tour?.natural_id,
              current_tour_status: tourUpdateMessage.tour?.status,
            };
          }
          editedVehicles.push(newVehicle);
        }
      });

      if (editedVehicles.length > 0) {
        return vehiclesAdapter.setMany(editedVehicles, state);
      } else {
        return state;
      }
    }

    case VehiclesActionTypes.ClearVehicles: {
      return vehiclesAdapter.removeAll(state);
    }

    case VehiclesActionTypes.ResetVehicles: {
      return Object.assign({}, vehiclesInitialState);
    }

    case VehiclesActionTypes.SetVehicleTypes: {
      return {
        ...state,
        vehicleTypes: [{ name: '-' }, ...action.payload.vehicleTypes],
      };
    }

    default: {
      return state;
    }
  }
}
