import { AuthActions, AuthActionTypes } from '../actions/auth.action';
import { Status } from '../../interfaces/status.enum';
import { JwtPayload, LoginResponse, User } from '@dispo-web/app/general/interfaces/auth.interface';
import { jwtDecode } from 'jwt-decode';
import { ErrorResponse } from '@dispo-web/app/general/interfaces/error.interface';
import { b64DecodeUnicode } from '@dispo-web/app/general/utils/b64-decode-unicode';

export interface AuthState {
  status: Status;
  user: User | null;
  tokens: LoginResponse | null;
  error: ErrorResponse | null;
}

export const authInitialState: AuthState = {
  status: Status.Default,
  user: null,
  tokens: null,
  error: null,
};

export function authReducer(state: AuthState = authInitialState, action: AuthActions): AuthState {
  switch (action.type) {
    case AuthActionTypes.SetStatus:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error ? action.payload.error : null,
      };

    case AuthActionTypes.Login: {
      const principal = (jwtDecode(action.payload.tokens.access_token.value) as JwtPayload)
        .principal;
      return {
        ...state,
        status: Status.Success,
        user: JSON.parse(b64DecodeUnicode(principal)),
        tokens: action.payload.tokens,
        error: null,
      };
    }
    case AuthActionTypes.Logout: {
      return authInitialState;
    }

    default:
      return state;
  }
}
