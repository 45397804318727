/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getGrants } from '../fn/roles/get-grants';
import { GetGrants$Params } from '../fn/roles/get-grants';
import { getRoles } from '../fn/roles/get-roles';
import { GetRoles$Params } from '../fn/roles/get-roles';
import { GrantsListResponse } from '../models/grants-list-response';
import { postRole } from '../fn/roles/post-role';
import { PostRole$Params } from '../fn/roles/post-role';
import { Role } from '../models/role';
import { RoleListResponse } from '../models/role-list-response';
import { updateRole } from '../fn/roles/update-role';
import { UpdateRole$Params } from '../fn/roles/update-role';

@Injectable({ providedIn: 'root' })
export class RolesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getGrants()` */
  static readonly GetGrantsPath = '/api/v1/grants';

  /**
   * Get list of grants.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGrants()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGrants$Response(params?: GetGrants$Params, context?: HttpContext): Observable<StrictHttpResponse<GrantsListResponse>> {
    return getGrants(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of grants.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGrants$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGrants(params?: GetGrants$Params, context?: HttpContext): Observable<GrantsListResponse> {
    return this.getGrants$Response(params, context).pipe(
      map((r: StrictHttpResponse<GrantsListResponse>): GrantsListResponse => r.body)
    );
  }

  /** Path part for operation `getRoles()` */
  static readonly GetRolesPath = '/api/v1/roles';

  /**
   * Get list of roles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoles$Response(params?: GetRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<RoleListResponse>> {
    return getRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of roles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoles(params?: GetRoles$Params, context?: HttpContext): Observable<RoleListResponse> {
    return this.getRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleListResponse>): RoleListResponse => r.body)
    );
  }

  /** Path part for operation `postRole()` */
  static readonly PostRolePath = '/api/v1/roles';

  /**
   * Post a new role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postRole$Response(params: PostRole$Params, context?: HttpContext): Observable<StrictHttpResponse<Role>> {
    return postRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Post a new role.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postRole(params: PostRole$Params, context?: HttpContext): Observable<Role> {
    return this.postRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<Role>): Role => r.body)
    );
  }

  /** Path part for operation `updateRole()` */
  static readonly UpdateRolePath = '/api/v1/roles/{id}';

  /**
   * Update a role by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRole$Response(params: UpdateRole$Params, context?: HttpContext): Observable<StrictHttpResponse<Role>> {
    return updateRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a role by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRole(params: UpdateRole$Params, context?: HttpContext): Observable<Role> {
    return this.updateRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<Role>): Role => r.body)
    );
  }

}
