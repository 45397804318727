/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BranchGroup } from '../../models/branch-group';
import { BranchGroupUpdateRequest } from '../../models/branch-group-update-request';

export interface UpdateBranchGroup$Params {

/**
 * Id of a resource.
 */
  id: string;
      body: BranchGroupUpdateRequest
}

export function updateBranchGroup(http: HttpClient, rootUrl: string, params: UpdateBranchGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<BranchGroup>> {
  const rb = new RequestBuilder(rootUrl, updateBranchGroup.PATH, 'put');
  if (params) {
    rb.path('id', params.id, {"style":"simple","explode":false});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<BranchGroup>;
    })
  );
}

updateBranchGroup.PATH = '/api/v1/branches/groups/{id}';
