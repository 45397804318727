import { Action } from '@ngrx/store';
import { Status } from '../../interfaces/status.enum';
import { ErrorResponse } from '@dispo-web/app/general/interfaces/error.interface';
import { Device } from '@dispo-shared/open-api/models';

export enum DevicesActionTypes {
  SetStatus = '[Devices] Set status',
  AddDevices = '[Devices] Add devices',
  AddDevice = '[Devices] Add device',
  ClearDevices = '[Devices] Clear devices',
  UpdateDevice = '[Devices] Update device',
  ResetDevices = '[Devices] Reset devices',
}

export class SetStatus implements Action {
  public readonly type = DevicesActionTypes.SetStatus;
  constructor(public payload: { status: Status; error?: ErrorResponse | null }) {}
}

export class AddDevices implements Action {
  public readonly type = DevicesActionTypes.AddDevices;
  constructor(public payload: { devices: Device[] }) {}
}

export class AddDevice implements Action {
  public readonly type = DevicesActionTypes.AddDevice;
  constructor(public payload: { device: Device }) {}
}

export class UpdateDevice implements Action {
  public readonly type = DevicesActionTypes.UpdateDevice;
  constructor(public payload: { device: Device; oldDevice?: Device }) {}
}

export class ClearDevices implements Action {
  public readonly type = DevicesActionTypes.ClearDevices;
}

export class ResetDevices implements Action {
  public readonly type = DevicesActionTypes.ResetDevices;
}

export type DevicesActions =
  | SetStatus
  | AddDevices
  | AddDevice
  | UpdateDevice
  | ClearDevices
  | ResetDevices;
