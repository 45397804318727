import { Component, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TenantConfigService } from './general/services/tenant-config.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WebSocketsService } from './general/services/web-sockets/web-sockets.service';
import { UsersStoreService } from './general/services/users-store.service';
import { IconsComponent } from '../../../../libs/shared-ui/src/lib/icons/icons.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'xyz-web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, IconsComponent],
})
export class AppComponent {
  private titleService = inject(Title);
  private tenantService = inject(TenantConfigService);
  private socketService = inject(WebSocketsService);
  private userStoreService = inject(UsersStoreService);

  favIcon: HTMLLinkElement | null = document.querySelector('#appIcon');

  constructor() {
    // Print build info
    // Need to so we can inspect the build version in the browser after deployment
    fetch('./assets/cicd/build_info')
      .then((response) => response.text())
      .then(console.log);
    fetch('./assets/cicd/version_info')
      .then((response) => response.text())
      .then((version) => {
        // If version is not empty, assign it to this.version
        if (version) {
          // Remove 'release-' prefix
          version = version.replace('release-', '');
          // Check if version starts with digit
          if (version.match(/^\d/)) {
            this.tenantService.setAppVersion(version);
          }
        }
      });
    // Set title
    this.titleService.setTitle(this.tenantService.tenantMeta().name || '');

    // Set favicon
    if (this.favIcon) {
      const favIconFile = `./assets/img/favicons/${this.tenantService.tenantShortCode()}.ico`;
      this.tenantService.fileExists(favIconFile).subscribe((exists) => {
        if (exists) {
          this.favIcon!.href = favIconFile;
        } else {
          this.favIcon!.href = `./assets/img/favicons/favicon_default.ico`;
        }
      });
    }

    // Set colors
    const colors = this.tenantService.tenantConfig().colors;
    for (const key in colors) {
      document.documentElement.style.setProperty(key, (colors as any)[key]);
    }

    // set connected status and store it storeService
    this.socketService.connectionSubject.pipe(takeUntilDestroyed()).subscribe((val) => {
      this.userStoreService.setConnected(val);
    });
  }
}
