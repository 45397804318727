/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CaseExternal } from '../../models/case-external';

export interface CreateCaseExternal$Params {

/**
 * tenant shortcode
 */
  tenant_short_code: string;
      body: CaseExternal
}

export function createCaseExternal(http: HttpClient, rootUrl: string, params: CreateCaseExternal$Params, context?: HttpContext): Observable<StrictHttpResponse<CaseExternal>> {
  const rb = new RequestBuilder(rootUrl, createCaseExternal.PATH, 'post');
  if (params) {
    rb.path('tenant_short_code', params.tenant_short_code, {"style":"simple","explode":false});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CaseExternal>;
    })
  );
}

createCaseExternal.PATH = '/api/v1/external/{tenant_short_code}/cases';
