/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CaseExternalListResponse } from '../../models/case-external-list-response';

export interface GetCasesExternal$Params {

/**
 * tenant shortcode
 */
  tenant_short_code: string;

/**
 * Pagination parameter. Skips page * size entries.
 */
  page?: number;

/**
 * Max number of results per page.
 */
  size?: number;

/**
 * Paramter to sort by as field(:(asc|desc))?. If missing default sort is used. field is the name in the db model not the db field name.
 */
  sort?: Array<string>;

/**
 * The from date
 */
  from?: string;

/**
 * The to date
 */
  to?: string;

/**
 * the status of the cases you want to fetch
 */
  status?: 'CREATED' | 'DELIVERED' | 'CANCELED';

/**
 * the transport type of the cases you want to fetch
 */
  transport_type?: 'Vorzeitige Abholung' | 'Transportauftrag' | 'Durchf\xFChrung 1' | 'Durchf\xFChrung 2' | 'Sonderaufbahrung' | 'Sonstiges';
}

export function getCasesExternal(http: HttpClient, rootUrl: string, params: GetCasesExternal$Params, context?: HttpContext): Observable<StrictHttpResponse<CaseExternalListResponse>> {
  const rb = new RequestBuilder(rootUrl, getCasesExternal.PATH, 'get');
  if (params) {
    rb.path('tenant_short_code', params.tenant_short_code, {"style":"simple","explode":false});
    rb.query('page', params.page, {"style":"form","explode":true});
    rb.query('size', params.size, {"style":"form","explode":true});
    rb.query('sort', params.sort, {"style":"form","explode":true});
    rb.query('from', params.from, {"style":"form","explode":true});
    rb.query('to', params.to, {"style":"form","explode":true});
    rb.query('status', params.status, {"style":"form","explode":true});
    rb.query('transport_type', params.transport_type, {"style":"form","explode":true});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CaseExternalListResponse>;
    })
  );
}

getCasesExternal.PATH = '/api/v1/external/{tenant_short_code}/cases';
