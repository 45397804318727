import { UserDialogsActions, UserDialogsActionTypes } from '../actions/user-dialogs.action';
import { Status } from '../../interfaces/status.enum';

export interface UserDialogsState {
  manageDialogOpenStatus: boolean;
  manageDialogRequestStatus: Status;
  manageDialogError: any;
}

export const userDialogsInitialState: UserDialogsState = {
  manageDialogOpenStatus: false,
  manageDialogRequestStatus: Status.Default,
  manageDialogError: null,
};

// eslint-disable-next-line max-len
export function userDialogsReducer(
  state: UserDialogsState = userDialogsInitialState,
  action: UserDialogsActions
): UserDialogsState {
  switch (action.type) {
    case UserDialogsActionTypes.SetManageDialogOpenStatus: {
      return {
        ...state,
        manageDialogOpenStatus: action.payload.open,
      };
    }

    case UserDialogsActionTypes.SetManageDialogRequestStatus: {
      return {
        ...state,
        manageDialogRequestStatus: action.payload.status,
        manageDialogError: action.payload.error ? action.payload.error : null,
      };
    }

    default: {
      return state;
    }
  }
}
