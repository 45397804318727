import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class ETagInterceptor implements HttpInterceptor {
  private eTags: { [key: string]: string } = {};
  private localStorageKey = 'eTags'

  constructor() {
    // Load eTags from localStorage
    const localStorageKey = localStorage.getItem(this.localStorageKey);
    if (localStorageKey) {
      this.eTags = JSON.parse(localStorageKey);
    }
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestToIntercept: string[] = ['areas']; // Array of requests to intercept

    const endpoint = request.url.split('/')[request.url.split('/').length - 1];
    const shouldIntercept = requestToIntercept.includes(endpoint);

    if (shouldIntercept) {
      const key = `etag-${endpoint}`;
      const eTag = this.eTags[key] || '';

      if (eTag) {
        request = request.clone({
          setHeaders: {
            'If-None-Match': eTag,
          },
        });
      }

      return next.handle(request).pipe(
        tap((event) => {
          if (event instanceof HttpResponse) {
            const eTagHeader = event.headers.get('ETag');
            if (eTagHeader) {
              this.eTags[key] = eTagHeader;
              localStorage.setItem(this.localStorageKey, JSON.stringify(this.eTags));
            }
          }
        }),
        catchError((error) => {
          if (error.status === 304) {
            return next
              .handle(
                request.clone({
                  headers: request.headers.delete('If-None-Match'),
                })
              )
              .pipe(
                catchError((secondError) => {
                  return of(secondError);
                })
              );
          }
          return of(error);
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
