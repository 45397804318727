/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createServiceAccountUser } from '../fn/service-account-users/create-service-account-user';
import { CreateServiceAccountUser$Params } from '../fn/service-account-users/create-service-account-user';
import { createTokenForExistingUser } from '../fn/service-account-users/create-token-for-existing-user';
import { CreateTokenForExistingUser$Params } from '../fn/service-account-users/create-token-for-existing-user';
import { findTokensByUserId } from '../fn/service-account-users/find-tokens-by-user-id';
import { FindTokensByUserId$Params } from '../fn/service-account-users/find-tokens-by-user-id';
import { getAllTokens } from '../fn/service-account-users/get-all-tokens';
import { GetAllTokens$Params } from '../fn/service-account-users/get-all-tokens';
import { ServiceAccountToken } from '../models/service-account-token';
import { ServiceAccountTokenResponse } from '../models/service-account-token-response';
import { updateTokenBlacklistedStatus } from '../fn/service-account-users/update-token-blacklisted-status';
import { UpdateTokenBlacklistedStatus$Params } from '../fn/service-account-users/update-token-blacklisted-status';
import { UserAccountService } from '../models/user-account-service';

@Injectable({ providedIn: 'root' })
export class ServiceAccountUsersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllTokens()` */
  static readonly GetAllTokensPath = '/api/v1/service_account_user/tokens';

  /**
   * Get all service account tokens.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllTokens()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTokens$Response(params?: GetAllTokens$Params, context?: HttpContext): Observable<StrictHttpResponse<ServiceAccountTokenResponse>> {
    return getAllTokens(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all service account tokens.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllTokens$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTokens(params?: GetAllTokens$Params, context?: HttpContext): Observable<ServiceAccountTokenResponse> {
    return this.getAllTokens$Response(params, context).pipe(
      map((r: StrictHttpResponse<ServiceAccountTokenResponse>): ServiceAccountTokenResponse => r.body)
    );
  }

  /** Path part for operation `createServiceAccountUser()` */
  static readonly CreateServiceAccountUserPath = '/api/v1/service_account_users';

  /**
   * Create new service account user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createServiceAccountUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createServiceAccountUser$Response(params: CreateServiceAccountUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserAccountService>> {
    return createServiceAccountUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new service account user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createServiceAccountUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createServiceAccountUser(params: CreateServiceAccountUser$Params, context?: HttpContext): Observable<UserAccountService> {
    return this.createServiceAccountUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserAccountService>): UserAccountService => r.body)
    );
  }

  /** Path part for operation `findTokensByUserId()` */
  static readonly FindTokensByUserIdPath = '/api/v1/service_account_users/{id}/tokens';

  /**
   * Get specific token for account_Service account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findTokensByUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTokensByUserId$Response(params: FindTokensByUserId$Params, context?: HttpContext): Observable<StrictHttpResponse<ServiceAccountTokenResponse>> {
    return findTokensByUserId(this.http, this.rootUrl, params, context);
  }

  /**
   * Get specific token for account_Service account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findTokensByUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTokensByUserId(params: FindTokensByUserId$Params, context?: HttpContext): Observable<ServiceAccountTokenResponse> {
    return this.findTokensByUserId$Response(params, context).pipe(
      map((r: StrictHttpResponse<ServiceAccountTokenResponse>): ServiceAccountTokenResponse => r.body)
    );
  }

  /** Path part for operation `createTokenForExistingUser()` */
  static readonly CreateTokenForExistingUserPath = '/api/v1/service_account_users/{id}/tokens';

  /**
   * Create new token for existing service_account user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTokenForExistingUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTokenForExistingUser$Response(params: CreateTokenForExistingUser$Params, context?: HttpContext): Observable<StrictHttpResponse<ServiceAccountToken>> {
    return createTokenForExistingUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new token for existing service_account user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTokenForExistingUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTokenForExistingUser(params: CreateTokenForExistingUser$Params, context?: HttpContext): Observable<ServiceAccountToken> {
    return this.createTokenForExistingUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<ServiceAccountToken>): ServiceAccountToken => r.body)
    );
  }

  /** Path part for operation `updateTokenBlacklistedStatus()` */
  static readonly UpdateTokenBlacklistedStatusPath = '/api/v1/service_account_users/{id}/tokens/{token_id}';

  /**
   * change value of blacklisted filed for service account token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTokenBlacklistedStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTokenBlacklistedStatus$Response(params: UpdateTokenBlacklistedStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<ServiceAccountToken>> {
    return updateTokenBlacklistedStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * change value of blacklisted filed for service account token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTokenBlacklistedStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTokenBlacklistedStatus(params: UpdateTokenBlacklistedStatus$Params, context?: HttpContext): Observable<ServiceAccountToken> {
    return this.updateTokenBlacklistedStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<ServiceAccountToken>): ServiceAccountToken => r.body)
    );
  }

}
