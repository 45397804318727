/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { assignRollbackRsaCase } from '../fn/rsa-tours/assign-rollback-rsa-case';
import { AssignRollbackRsaCase$Params } from '../fn/rsa-tours/assign-rollback-rsa-case';
import { assignRsaCase } from '../fn/rsa-tours/assign-rsa-case';
import { AssignRsaCase$Params } from '../fn/rsa-tours/assign-rsa-case';
import { forwardRsaCase } from '../fn/rsa-tours/forward-rsa-case';
import { ForwardRsaCase$Params } from '../fn/rsa-tours/forward-rsa-case';
import { getRsaTours } from '../fn/rsa-tours/get-rsa-tours';
import { GetRsaTours$Params } from '../fn/rsa-tours/get-rsa-tours';
import { PaginatedTourListResponse } from '../models/paginated-tour-list-response';
import { Tour } from '../models/tour';
import { updatePrimaryState } from '../fn/rsa-tours/update-primary-state';
import { UpdatePrimaryState$Params } from '../fn/rsa-tours/update-primary-state';
import { updateRsaTourSubState } from '../fn/rsa-tours/update-rsa-tour-sub-state';
import { UpdateRsaTourSubState$Params } from '../fn/rsa-tours/update-rsa-tour-sub-state';

@Injectable({ providedIn: 'root' })
export class RsaToursService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getRsaTours()` */
  static readonly GetRsaToursPath = '/api/v1/rsa/tours';

  /**
   * Get paginated list of this resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRsaTours()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRsaTours$Response(params?: GetRsaTours$Params, context?: HttpContext): Observable<StrictHttpResponse<PaginatedTourListResponse>> {
    return getRsaTours(this.http, this.rootUrl, params, context);
  }

  /**
   * Get paginated list of this resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRsaTours$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRsaTours(params?: GetRsaTours$Params, context?: HttpContext): Observable<PaginatedTourListResponse> {
    return this.getRsaTours$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaginatedTourListResponse>): PaginatedTourListResponse => r.body)
    );
  }

  /** Path part for operation `assignRollbackRsaCase()` */
  static readonly AssignRollbackRsaCasePath = '/api/v1/rsa/tours/forward_rollback_case';

  /**
   * Create association between tour and case.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignRollbackRsaCase()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignRollbackRsaCase$Response(params: AssignRollbackRsaCase$Params, context?: HttpContext): Observable<StrictHttpResponse<Tour>> {
    return assignRollbackRsaCase(this.http, this.rootUrl, params, context);
  }

  /**
   * Create association between tour and case.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assignRollbackRsaCase$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignRollbackRsaCase(params: AssignRollbackRsaCase$Params, context?: HttpContext): Observable<Tour> {
    return this.assignRollbackRsaCase$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tour>): Tour => r.body)
    );
  }

  /** Path part for operation `assignRsaCase()` */
  static readonly AssignRsaCasePath = '/api/v1/rsa/tours/{id}/assign_case';

  /**
   * Create association between tour and case.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignRsaCase()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignRsaCase$Response(params: AssignRsaCase$Params, context?: HttpContext): Observable<StrictHttpResponse<Tour>> {
    return assignRsaCase(this.http, this.rootUrl, params, context);
  }

  /**
   * Create association between tour and case.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assignRsaCase$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignRsaCase(params: AssignRsaCase$Params, context?: HttpContext): Observable<Tour> {
    return this.assignRsaCase$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tour>): Tour => r.body)
    );
  }

  /** Path part for operation `forwardRsaCase()` */
  static readonly ForwardRsaCasePath = '/api/v1/rsa/tours/{id}/forward_assign_case';

  /**
   * Create association between tour and case.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `forwardRsaCase()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  forwardRsaCase$Response(params: ForwardRsaCase$Params, context?: HttpContext): Observable<StrictHttpResponse<Tour>> {
    return forwardRsaCase(this.http, this.rootUrl, params, context);
  }

  /**
   * Create association between tour and case.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `forwardRsaCase$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  forwardRsaCase(params: ForwardRsaCase$Params, context?: HttpContext): Observable<Tour> {
    return this.forwardRsaCase$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tour>): Tour => r.body)
    );
  }

  /** Path part for operation `updatePrimaryState()` */
  static readonly UpdatePrimaryStatePath = '/api/v1/rsa/tours/{id}/primary';

  /**
   * Update primary state of a tour.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePrimaryState()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePrimaryState$Response(params: UpdatePrimaryState$Params, context?: HttpContext): Observable<StrictHttpResponse<Tour>> {
    return updatePrimaryState(this.http, this.rootUrl, params, context);
  }

  /**
   * Update primary state of a tour.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePrimaryState$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePrimaryState(params: UpdatePrimaryState$Params, context?: HttpContext): Observable<Tour> {
    return this.updatePrimaryState$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tour>): Tour => r.body)
    );
  }

  /** Path part for operation `updateRsaTourSubState()` */
  static readonly UpdateRsaTourSubStatePath = '/api/v1/rsa/tours/{id}/started_sub_state';

  /**
   * Update existing started tour.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRsaTourSubState()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRsaTourSubState$Response(params: UpdateRsaTourSubState$Params, context?: HttpContext): Observable<StrictHttpResponse<Tour>> {
    return updateRsaTourSubState(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing started tour.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRsaTourSubState$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRsaTourSubState(params: UpdateRsaTourSubState$Params, context?: HttpContext): Observable<Tour> {
    return this.updateRsaTourSubState$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tour>): Tour => r.body)
    );
  }

}
