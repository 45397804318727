/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AuthUserResponse } from '../../models/auth-user-response';
import { SetPasswordRequest } from '../../models/set-password-request';

export interface SetPassword$Params {

/**
 * uuid key of the tenant
 */
  'X-Tenant-Key'?: string;
      body: SetPasswordRequest
}

export function setPassword(http: HttpClient, rootUrl: string, params: SetPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthUserResponse>> {
  const rb = new RequestBuilder(rootUrl, setPassword.PATH, 'post');
  if (params) {
    rb.header('X-Tenant-Key', params['X-Tenant-Key'], {"style":"simple","explode":false});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<AuthUserResponse>;
    })
  );
}

setPassword.PATH = '/api/v1/auth/user/password/reset/set';
