/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { fetchHealthUsingGet1 } from '../fn/status-api/fetch-health-using-get-1';
import { FetchHealthUsingGet1$Params } from '../fn/status-api/fetch-health-using-get-1';
import { ResponseEntity } from '../models/response-entity';


/**
 * Status API
 */
@Injectable({ providedIn: 'root' })
export class StatusApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `fetchHealthUsingGet1()` */
  static readonly FetchHealthUsingGet1Path = '/v2/status';

  /**
   * fetchHealth.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchHealthUsingGet1()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchHealthUsingGet1$Response(params?: FetchHealthUsingGet1$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseEntity>> {
    return fetchHealthUsingGet1(this.http, this.rootUrl, params, context);
  }

  /**
   * fetchHealth.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fetchHealthUsingGet1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchHealthUsingGet1(params?: FetchHealthUsingGet1$Params, context?: HttpContext): Observable<ResponseEntity> {
    return this.fetchHealthUsingGet1$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseEntity>): ResponseEntity => r.body)
    );
  }

}
