import { Action } from '@ngrx/store';
import { LoginResponse } from '@dispo-web/app/general/interfaces/auth.interface';
import { ErrorResponse } from '@dispo-web/app/general/interfaces/error.interface';
import { Status } from '../../interfaces/status.enum';

export enum AuthActionTypes {
  SetStatus = '[Auth] Set status',
  Login = '[Auth] Login',
  Logout = '[Auth] Logout',
}

export class SetStatus implements Action {
  public readonly type = AuthActionTypes.SetStatus;
  constructor(public payload: { status: Status; error?: ErrorResponse }) {}
}

export class Login implements Action {
  public readonly type = AuthActionTypes.Login;
  constructor(public payload: { tokens: LoginResponse; refresh: boolean }) {}
}

export class Logout implements Action {
  public readonly type = AuthActionTypes.Logout;
}

export type AuthActions = SetStatus | Login | Logout;
