/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, inject } from '@angular/core';
import { Client as StompClient, StompSubscription } from '@stomp/stompjs';
import { AuthService } from '@dispo-web/app/general/services/auth.service';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthState } from '@dispo-web/app/general/store/reducers/auth.reducer';
import { Login, SetStatus } from '@dispo-web/app/general/store/actions/auth.action';
import { Status } from '@dispo-web/app/general/interfaces/status.enum';
import { selectUser } from '@dispo-web/app/general/store/selectors/auth.selector';
import { filter, take } from 'rxjs/operators';
import { WebSocketCaseActionMessage } from '@dispo-shared/open-api/models/web-socket-case-action-message';
import { WebSocketCaseCommentMessage } from '@dispo-shared/open-api/models/web-socket-case-comment-message';
import { WebSocketTourUpdateMessage } from '@dispo-shared/open-api/models/web-socket-tour-update-message';
import { WebSocketLocationUpdateMessage } from '@dispo-shared/open-api/models/web-socket-location-update-message';
import { WebSocketCaseUpdateMessage } from '@dispo-shared/open-api/models/web-socket-case-update-message';
import { LoginResponse } from '@dispo-web/app/general/interfaces/auth.interface';
import { WebSocketTourDeleteMessage } from '@dispo-shared/open-api/models/web-socket-tour-delete-message';
import { VehiclesLocationsService } from '@dispo-shared/shared-ui/services/vehicle-locations/vehicle-locations.service';
import { SpaConfig } from '@dispo-shared/spa-config/spa-config';

@Injectable({
  providedIn: 'root',
})
export class WebSocketsService {
  private auth = inject(AuthService);
  private authStore = inject<Store<AuthState>>(Store);
  private vehiclesLocationsService = inject(VehiclesLocationsService);

  connected = false;
  public caseCreationMessages: Subject<WebSocketCaseUpdateMessage> = new Subject();
  public tourCaseActionMessages: Subject<WebSocketCaseActionMessage> = new Subject();

  public commentMessages: Subject<WebSocketCaseCommentMessage> = new Subject();
  public tourUpdateMessages: Subject<WebSocketTourUpdateMessage> = new Subject();
  public tourCreateMessages: Subject<WebSocketTourUpdateMessage> = new Subject();
  public tourDeleteMessages: Subject<WebSocketTourDeleteMessage> = new Subject();
  public locationMessages: Subject<WebSocketLocationUpdateMessage> = new Subject();
  public caseUpdateMessages: Subject<WebSocketCaseUpdateMessage> = new Subject();
  public connectionSubject: Subject<boolean> = new Subject();

  private tokenId: string | null | undefined = null;
  private brokerUrl = '';
  private stompClient: StompClient | null = null;
  private subscriptions: StompSubscription[] = [];
  private tenantShortCode!: string | null;

  private pingSenderIntervalId: any;

  constructor() {
    const apiBaseUrl = SpaConfig.apiUrlProvider.rootUrl;
    let envBasedBrokerUrl = `${apiBaseUrl}/websocket`;
    envBasedBrokerUrl = envBasedBrokerUrl.includes('https')
      ? envBasedBrokerUrl.replace('https', 'wss')
      : envBasedBrokerUrl.replace('http', 'ws');
    this.brokerUrl = envBasedBrokerUrl;
  }

  /**
   * This method creates internally a Stompclient and connects to our backend
   */
  async connect(): Promise<void> {
    this.createStompClient();
  }

  /**
   * This method unsubscribes the Stompclient from all the subscriptions, deactivates the Stompclient and closes the connection with the backend
   */
  disconnect(): void {
    if (this.pingSenderIntervalId) {
      clearInterval(this.pingSenderIntervalId);
    }
    this.subscriptions.forEach((value) => value.unsubscribe());
    this.subscriptions = [];
    this.stompClient?.deactivate();
    this.stompClient = null;
    this.updateConnectionSubject();
  }

  /**
   * This private method creates the Stompclient that we use for the Web-Socket connectivity with our backend.
   * The method also binds all the internal functionalities to our overridden external methods
   */
  private createStompClient(): void {
    this.authStore
      .select(selectUser)
      .pipe(
        filter((result) => result !== null),
        take(1)
      )
      .subscribe((user) => {
        this.tenantShortCode = user?.tenant.shortCode || null;
        this.tokenId = this.auth.getTokenId() as string;

        this.stompClient = new StompClient({
          connectHeaders: {
            'x-jwt-token': this.tokenId,
          },
          connectionTimeout: 300000,
          heartbeatIncoming: 0,
          heartbeatOutgoing: 0,
          webSocketFactory: () => {
            const ws = new WebSocket(this.brokerUrl);

            // Ping sender every 5 seconds
            this.clearPingSenderInterval();
            this.pingSenderIntervalId = setInterval(() => {
              ws.send('');
            }, 5000);

            return ws;
          },

          onConnect: this.stompOnConnect.bind(this), // frameCallbackType
          onDisconnect: this.stompOnDisconnect.bind(this), // frameCallbackType
          onStompError: this.stompOnStompError.bind(this), // frameCallbackType
          onUnhandledFrame: this.stompOnUnhandledFrame.bind(this), // frameCallbackType
          onUnhandledReceipt: this.stompOnUnhandledReceipt.bind(this), // frameCallbackType
          onUnhandledMessage: this.stompOnUnhandledMessage.bind(this), // messageCallbackType
          onWebSocketClose: this.stompOnWebsocketClose.bind(this), // closeEventCallbackType
          onWebSocketError: this.stompOnWebsocketError.bind(this), // wsErrorCallbackType
          onChangeState: this.stompOnChangeState.bind(this), // function
        });

        this.stompClient?.activate();
      });
  }

  private clearPingSenderInterval(): void {
    if (this.pingSenderIntervalId) {
      clearInterval(this.pingSenderIntervalId);
      this.pingSenderIntervalId = null;
    }
  }

  private handleTourCaseActionTopic(value: any): void {
    const caseActionMessage: WebSocketCaseActionMessage = JSON.parse(value.body);
    this.tourCaseActionMessages.next(caseActionMessage);
  }

  private handleCaseCreatedTopic(value: any): void {
    const caseCreateMessage: WebSocketCaseUpdateMessage = JSON.parse(value.body);
    this.caseCreationMessages.next(caseCreateMessage);
  }

  private handleCaseUpdatedTopic(value: any): void {
    const caseCreateMessage: WebSocketCaseUpdateMessage = JSON.parse(value.body);
    this.caseUpdateMessages.next(caseCreateMessage);
  }

  private handleCaseCommentTopic(value: any): void {
    const caseCommentMessage: WebSocketCaseCommentMessage = JSON.parse(value.body);
    this.commentMessages.next(caseCommentMessage);
  }

  private handleTourUpdatedTopic(value: any): void {
    const tourUpdateMessage: WebSocketTourUpdateMessage = JSON.parse(value.body);
    this.tourUpdateMessages.next(tourUpdateMessage);
  }

  private handleTourCreatedTopic(value: any): void {
    const tourCreateMessage: WebSocketTourUpdateMessage = JSON.parse(value.body);
    this.tourCreateMessages.next(tourCreateMessage);
  }

  private handleTourDeletedTopic(value: any): void {
    const tourDeleteMessage: WebSocketTourDeleteMessage = JSON.parse(value.body);
    this.tourDeleteMessages.next(tourDeleteMessage);
  }

  private handleLocationUpdatedTopic(value: any): void {
    const locationUpdateMessage: WebSocketLocationUpdateMessage = JSON.parse(value.body);
    this.locationMessages.next(locationUpdateMessage);
    this.vehiclesLocationsService.updateVehicleLocationFromWebSocketMessage(locationUpdateMessage);
  }

  /**
   * This is an overridden method from Stompclient that is called after the WS connection is established
   * In this method we subscribe to all the topics that we need
   * @returns Nothing but creates the subscriptions
   */
  private stompOnConnect(): void {
    this.updateConnectionSubject();

    if (this.stompClient === null) {
      console.error('stompClient is null');
      return;
    }

    if (this.tenantShortCode === null) {
      console.error('tenant shortcode is not available');
      return;
    }

    // Tour case action (received when a tour-case association is created/changed/deleted)
    const tourCaseActionTopicName = `/secured/topic/t/${this.tenantShortCode}/tour_case_actions`;
    this.subscriptions.push(
      this.stompClient.subscribe(
        tourCaseActionTopicName,
        (value) => {
          this.handleTourCaseActionTopic(value);
        },
        {}
      )
    );

    // Case created (received when a tour-case association is created/changed/deleted)
    const caseCreatedTopicName = `/secured/topic/t/${this.tenantShortCode}/case_created`;
    this.subscriptions.push(
      this.stompClient.subscribe(
        caseCreatedTopicName,
        (value) => {
          this.handleCaseCreatedTopic(value);
        },
        {}
      )
    );

    // Case created (received when a tour-case association is created/changed/deleted)
    const caseUpdatedTopicName = `/secured/topic/t/${this.tenantShortCode}/case_updated`;
    this.subscriptions.push(
      this.stompClient.subscribe(
        caseUpdatedTopicName,
        (value) => {
          this.handleCaseUpdatedTopic(value);
        },
        {}
      )
    );

    // Case comment created (received when a case comment is created)
    const caseCommentTopicName = `/secured/topic/t/${this.tenantShortCode}/comments`;
    this.subscriptions.push(
      this.stompClient.subscribe(
        caseCommentTopicName,
        (value) => {
          this.handleCaseCommentTopic(value);
        },
        {}
      )
    );

    // Tour created
    const tourCreatedTopicName = `/secured/topic/t/${this.tenantShortCode}/tour_created`;
    this.subscriptions.push(
      this.stompClient.subscribe(
        tourCreatedTopicName,
        (value) => {
          this.handleTourCreatedTopic(value);
        },
        {}
      )
    );

    // Tour updated
    const tourUpdatedTopicName = `/secured/topic/t/${this.tenantShortCode}/tour_updated`;
    this.subscriptions.push(
      this.stompClient.subscribe(
        tourUpdatedTopicName,
        (value) => {
          this.handleTourUpdatedTopic(value);
        },
        {}
      )
    );

    // Tour deleted
    const tourDeletedTopicName = `/secured/topic/t/${this.tenantShortCode}/tour_deleted`;
    this.subscriptions.push(
      this.stompClient.subscribe(
        tourDeletedTopicName,
        (value) => {
          this.handleTourDeletedTopic(value);
        },
        {}
      )
    );

    // Location updated for vehicle
    const locationUpdatedTopicName = `/secured/topic/t/${this.tenantShortCode}/location_updates`;
    this.subscriptions.push(
      this.stompClient.subscribe(
        locationUpdatedTopicName,
        (value) => {
          this.handleLocationUpdatedTopic(value);
        },
        {}
      )
    );
  }

  /**
   * This is an overridden method from Stompclient that is called after the WS connection is disconnected
   * @returns Nothing
   */
  private stompOnDisconnect(): void {
    console.debug('--stompOnDisconnect--');
  }

  /**
   * This is an overridden method from Stompclient that is called when an error occurs with the Stompclient and WS connection
   * In this method we handle the reconnection strategy
   * @returns Nothing but creates the subscriptions
   */
  private stompOnStompError(error: any): void {
    console.error('socket error:', error);
    this.disconnect();
    const token = this.auth.getTokenId();
    const refToken = this.auth.getRefreshToken();
    if (token != null && refToken != null) {
      this.auth.refreshAccessToken().subscribe({
        next: (refreshTokenResponse) => {
          if (
            typeof refreshTokenResponse !== 'string' &&
            refreshTokenResponse.access_token &&
            refreshTokenResponse.access_token.valid_until &&
            refreshTokenResponse.access_token.value &&
            refreshTokenResponse.refresh_token &&
            refreshTokenResponse.refresh_token.valid_until &&
            refreshTokenResponse.refresh_token.value &&
            refreshTokenResponse.id
          ) {
            this.authStore.dispatch(
              new Login({
                tokens: refreshTokenResponse as LoginResponse,
                refresh: false,
              })
            );
            this.authStore.dispatch(new SetStatus({ status: Status.Success }));
            setTimeout(() => {
              this.restartConnection();
            }, 5000);
          }
        },
        error: () => {
          this.disconnect();
          this.auth.logout();
        },
      });
    }
  }

  /**
   * This is an overridden method from Stompclient (We don't use this method)
   * @returns Nothing
   */
  private stompOnUnhandledFrame(): void {}

  /**
   * This is an overridden method from Stompclient (We don't use this method)
   * @returns Nothing
   */
  private stompOnUnhandledReceipt(): void {}

  /**
   * This is an overridden method from Stompclient (We don't use this method)
   * @returns Nothing
   */
  private stompOnUnhandledMessage(): void {}

  /**
   * This is an overridden method from Stompclient (We don't use this method)
   * @returns Nothing
   */
  private stompOnWebsocketClose(): void {}

  /**
   * This is an overridden method from Stompclient that is called when an error occurs with the Stompclient and WS connection
   * In this method we handle the health notification of our WS connection
   * @returns Nothing but notifies the subject that an error occured
   */
  private stompOnWebsocketError(): void {
    console.log('--stompOnWebsocketError--');
    this.updateConnectionSubject();
  }

  /**
   * This is an overridden method from Stompclient (We don't use this method)
   * @returns Nothing
   */
  private stompOnChangeState(): void {
    console.debug('--stompOnChangeState--', this.stompClient?.connected);
  }

  /**
   * This a method that is used for simplification to restart the complete connection of the WS service
   * @returns Nothing
   */
  private restartConnection(): void {
    this.disconnect();
    this.connect();
  }

  private updateConnectionSubject(): void {
    if (
      this.stompClient == null ||
      this.stompClient.connected == undefined ||
      this.stompClient.connected == null ||
      this.stompClient.connected == false
    ) {
      this.connected = false;
      this.connectionSubject.next(false);
    } else {
      this.connected = true;
      this.connectionSubject.next(true);
    }
  }
}
