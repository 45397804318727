/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createVehicleType } from '../fn/vehicle-types/create-vehicle-type';
import { CreateVehicleType$Params } from '../fn/vehicle-types/create-vehicle-type';
import { getVehicleTypes } from '../fn/vehicle-types/get-vehicle-types';
import { GetVehicleTypes$Params } from '../fn/vehicle-types/get-vehicle-types';
import { VehicleType } from '../models/vehicle-type';
import { VehicleTypeListResponse } from '../models/vehicle-type-list-response';

@Injectable({ providedIn: 'root' })
export class VehicleTypesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getVehicleTypes()` */
  static readonly GetVehicleTypesPath = '/api/v1/vehicle_types';

  /**
   * Get list of vehicle types.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehicleTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleTypes$Response(params?: GetVehicleTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<VehicleTypeListResponse>> {
    return getVehicleTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of vehicle types.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVehicleTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleTypes(params?: GetVehicleTypes$Params, context?: HttpContext): Observable<VehicleTypeListResponse> {
    return this.getVehicleTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<VehicleTypeListResponse>): VehicleTypeListResponse => r.body)
    );
  }

  /** Path part for operation `createVehicleType()` */
  static readonly CreateVehicleTypePath = '/api/v1/vehicle_types';

  /**
   * Create new vehicle type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createVehicleType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createVehicleType$Response(params: CreateVehicleType$Params, context?: HttpContext): Observable<StrictHttpResponse<VehicleType>> {
    return createVehicleType(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new vehicle type.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createVehicleType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createVehicleType(params: CreateVehicleType$Params, context?: HttpContext): Observable<VehicleType> {
    return this.createVehicleType$Response(params, context).pipe(
      map((r: StrictHttpResponse<VehicleType>): VehicleType => r.body)
    );
  }

}
