/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DirectionsService } from '../../models/directions-service';
import { GeoJsonRouteResponse } from '../../models/geo-json-route-response';

export interface GetGeoJsonRouteUsingPost$Json$Params {

/**
 * Specifies the route profile.
 */
  profile: 'driving-car' | 'driving-hgv' | 'cycling-regular' | 'cycling-road' | 'cycling-mountain' | 'cycling-electric' | 'foot-walking' | 'foot-hiking' | 'wheelchair';
  
    /**
     * The request payload
     */
    body: DirectionsService
}

export function getGeoJsonRouteUsingPost$Json(http: HttpClient, rootUrl: string, params: GetGeoJsonRouteUsingPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<GeoJsonRouteResponse>> {
  const rb = new RequestBuilder(rootUrl, getGeoJsonRouteUsingPost$Json.PATH, 'post');
  if (params) {
    rb.path('profile', params.profile, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/geo+json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GeoJsonRouteResponse>;
    })
  );
}

getGeoJsonRouteUsingPost$Json.PATH = '/v2/directions/{profile}/geojson';
