/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GeocodingAddressListResponse } from '../../models/geocoding-address-list-response';

export interface StructuredSearch$Params {
  street?: string;
  city?: string;
  postalcode?: string;
  country?: string;
  geocoder?: string;
}

export function structuredSearch(http: HttpClient, rootUrl: string, params?: StructuredSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<GeocodingAddressListResponse>> {
  const rb = new RequestBuilder(rootUrl, structuredSearch.PATH, 'get');
  if (params) {
    rb.query('street', params.street, {"style":"form","explode":true});
    rb.query('city', params.city, {"style":"form","explode":true});
    rb.query('postalcode', params.postalcode, {"style":"form","explode":true});
    rb.query('country', params.country, {"style":"form","explode":true});
    rb.query('geocoder', params.geocoder, {"style":"form","explode":true});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GeocodingAddressListResponse>;
    })
  );
}

structuredSearch.PATH = '/api/v1/geocoding/addresses/search/structured';
