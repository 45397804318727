/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createResponsibilityClaims } from '../fn/responsibility-claims/create-responsibility-claims';
import { CreateResponsibilityClaims$Params } from '../fn/responsibility-claims/create-responsibility-claims';
import { getResponsibilityClaims } from '../fn/responsibility-claims/get-responsibility-claims';
import { GetResponsibilityClaims$Params } from '../fn/responsibility-claims/get-responsibility-claims';
import { ResponsibilityClaimsListResponse } from '../models/responsibility-claims-list-response';
import { ResponsibilityClaimsPerUserListResponse } from '../models/responsibility-claims-per-user-list-response';

@Injectable({ providedIn: 'root' })
export class ResponsibilityClaimsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getResponsibilityClaims()` */
  static readonly GetResponsibilityClaimsPath = '/api/v1/responsibility_claims';

  /**
   * Get responsibility claims.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResponsibilityClaims()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResponsibilityClaims$Response(params?: GetResponsibilityClaims$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponsibilityClaimsPerUserListResponse>> {
    return getResponsibilityClaims(this.http, this.rootUrl, params, context);
  }

  /**
   * Get responsibility claims.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getResponsibilityClaims$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResponsibilityClaims(params?: GetResponsibilityClaims$Params, context?: HttpContext): Observable<ResponsibilityClaimsPerUserListResponse> {
    return this.getResponsibilityClaims$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponsibilityClaimsPerUserListResponse>): ResponsibilityClaimsPerUserListResponse => r.body)
    );
  }

  /** Path part for operation `createResponsibilityClaims()` */
  static readonly CreateResponsibilityClaimsPath = '/api/v1/responsibility_claims';

  /**
   * Create responsibility claims.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createResponsibilityClaims()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createResponsibilityClaims$Response(params: CreateResponsibilityClaims$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponsibilityClaimsListResponse>> {
    return createResponsibilityClaims(this.http, this.rootUrl, params, context);
  }

  /**
   * Create responsibility claims.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createResponsibilityClaims$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createResponsibilityClaims(params: CreateResponsibilityClaims$Params, context?: HttpContext): Observable<ResponsibilityClaimsListResponse> {
    return this.createResponsibilityClaims$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponsibilityClaimsListResponse>): ResponsibilityClaimsListResponse => r.body)
    );
  }

}
