/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CaseExternal } from '../models/case-external';
import { CaseExternalListResponse } from '../models/case-external-list-response';
import { createCaseExternal } from '../fn/external/create-case-external';
import { CreateCaseExternal$Params } from '../fn/external/create-case-external';
import { getCasesExternal } from '../fn/external/get-cases-external';
import { GetCasesExternal$Params } from '../fn/external/get-cases-external';
import { triggerSync } from '../fn/external/trigger-sync';
import { TriggerSync$Params } from '../fn/external/trigger-sync';

@Injectable({ providedIn: 'root' })
export class ExternalService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCasesExternal()` */
  static readonly GetCasesExternalPath = '/api/v1/external/{tenant_short_code}/cases';

  /**
   * Get paginated list of cases.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCasesExternal()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCasesExternal$Response(params: GetCasesExternal$Params, context?: HttpContext): Observable<StrictHttpResponse<CaseExternalListResponse>> {
    return getCasesExternal(this.http, this.rootUrl, params, context);
  }

  /**
   * Get paginated list of cases.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCasesExternal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCasesExternal(params: GetCasesExternal$Params, context?: HttpContext): Observable<CaseExternalListResponse> {
    return this.getCasesExternal$Response(params, context).pipe(
      map((r: StrictHttpResponse<CaseExternalListResponse>): CaseExternalListResponse => r.body)
    );
  }

  /** Path part for operation `createCaseExternal()` */
  static readonly CreateCaseExternalPath = '/api/v1/external/{tenant_short_code}/cases';

  /**
   * Create new case.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCaseExternal()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCaseExternal$Response(params: CreateCaseExternal$Params, context?: HttpContext): Observable<StrictHttpResponse<CaseExternal>> {
    return createCaseExternal(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new case.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCaseExternal$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCaseExternal(params: CreateCaseExternal$Params, context?: HttpContext): Observable<CaseExternal> {
    return this.createCaseExternal$Response(params, context).pipe(
      map((r: StrictHttpResponse<CaseExternal>): CaseExternal => r.body)
    );
  }

  /** Path part for operation `triggerSync()` */
  static readonly TriggerSyncPath = '/api/v1/{tenant_short_code}/sync';

  /**
   * Trigger a sync.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `triggerSync()` instead.
   *
   * This method doesn't expect any request body.
   */
  triggerSync$Response(params: TriggerSync$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return triggerSync(this.http, this.rootUrl, params, context);
  }

  /**
   * Trigger a sync.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `triggerSync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  triggerSync(params: TriggerSync$Params, context?: HttpContext): Observable<void> {
    return this.triggerSync$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
