import { Action } from '@ngrx/store';
import { Status } from '../../interfaces/status.enum';
import { ErrorResponse } from '@dispo-web/app/general/interfaces/error.interface';
import { User } from '@dispo-shared/open-api/models';

export enum UsersActionTypes {
  SetStatus = '[Users] Set status',
  AddUsers = '[Users] Add users',
  AddUser = '[Users] Add user',
  ClearUsers = '[Users] Clear users',
  UpdateUser = '[Users] Update user',
  ResetUsers = '[Users] Reset users',
  SetUsers = '[Users] Set users',
}

export class SetStatus implements Action {
  public readonly type = UsersActionTypes.SetStatus;
  constructor(public payload: { status: Status; error?: ErrorResponse | null }) {}
}

export class AddUsers implements Action {
  public readonly type = UsersActionTypes.AddUsers;
  constructor(public payload: { users: User[] }) {}
}

export class AddUser implements Action {
  public readonly type = UsersActionTypes.AddUser;
  constructor(public payload: { user: User }) {}
}

export class UpdateUser implements Action {
  public readonly type = UsersActionTypes.UpdateUser;
  constructor(public payload: { user: User }) {}
}

export class ClearUsers implements Action {
  public readonly type = UsersActionTypes.ClearUsers;
}

export class ResetUsers implements Action {
  public readonly type = UsersActionTypes.ResetUsers;
}

export class SetUsers implements Action {
  public readonly type = UsersActionTypes.SetUsers;
  constructor(public payload: { users: User[] }) {}
}

export type UsersActions =
  | SetStatus
  | AddUsers
  | AddUser
  | UpdateUser
  | ClearUsers
  | ResetUsers
  | SetUsers;
