import { RolesActions, RolesActionTypes } from '../actions/roles.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Status } from '../../interfaces/status.enum';
import { Role, Skill } from '@dispo-shared/open-api/models';

export interface RolesState extends EntityState<Role> {
  status: Status;
  skills: Skill[];
  skillsStatus: Status;
  error?: string | null;
}

export const rolesAdapter: EntityAdapter<Role> = createEntityAdapter<Role>();

export const rolesInitialState: RolesState = {
  ...rolesAdapter.getInitialState(),
  status: Status.Default,
  skills: [],
  skillsStatus: Status.Default,
  error: null,
};

export function rolesReducer(
  state: RolesState = rolesInitialState,
  action: RolesActions
): RolesState {
  switch (action.type) {
    case RolesActionTypes.SetStatus: {
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error ? action.payload.error : null,
      };
    }

    case RolesActionTypes.SetRoles: {
      return rolesAdapter.setAll(action.payload.roles, state);
    }

    case RolesActionTypes.ResetRoles: {
      return Object.assign({}, rolesInitialState);
    }

    case RolesActionTypes.SetSkills: {
      return {
        ...state,
        skills: action.payload.skills,
      };
    }

    case RolesActionTypes.SetSkillsStatus: {
      return {
        ...state,
        skillsStatus: action.payload.status,
        error: action.payload.error ? action.payload.error : null,
      };
    }

    default: {
      return state;
    }
  }
}
