import { DevicesActions, DevicesActionTypes } from '../actions/devices.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Status } from '../../interfaces/status.enum';
import { ErrorResponse } from '@dispo-web/app/general/interfaces/error.interface';
import { Device } from '@dispo-shared/open-api/models';

export interface DevicesState extends EntityState<Device> {
  status: Status;
  error: ErrorResponse | null;
}

export const devicesAdapter: EntityAdapter<Device> = createEntityAdapter<Device>();

export const devicesInitialState: DevicesState = {
  ...devicesAdapter.getInitialState(),
  status: Status.Default,
  error: null,
};

export function devicesReducer(
  state: DevicesState = devicesInitialState,
  action: DevicesActions
): DevicesState {
  switch (action.type) {
    case DevicesActionTypes.SetStatus: {
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error || null,
      };
    }

    case DevicesActionTypes.AddDevices: {
      return devicesAdapter.addMany(action.payload.devices, state);
    }

    case DevicesActionTypes.AddDevice: {
      return devicesAdapter.addOne(action.payload.device, state);
    }

    case DevicesActionTypes.UpdateDevice: {
      return devicesAdapter.setOne(action.payload.device, state);
    }

    case DevicesActionTypes.ClearDevices: {
      return devicesAdapter.removeAll(state);
    }

    case DevicesActionTypes.ResetDevices: {
      return Object.assign({}, devicesInitialState);
    }

    default: {
      return state;
    }
  }
}
