/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getDefaultUsingPost$Json } from '../fn/matrix/get-default-using-post-json';
import { GetDefaultUsingPost$Json$Params } from '../fn/matrix/get-default-using-post-json';
import { getDefaultUsingPost$JsonCharsetUtf8 } from '../fn/matrix/get-default-using-post-json-charset-utf-8';
import { GetDefaultUsingPost$JsonCharsetUtf8$Params } from '../fn/matrix/get-default-using-post-json-charset-utf-8';
import { JsonMatrixResponse } from '../models/json-matrix-response';


/**
 * Obtain one-to-many, many-to-one and many-to-many matrices for time and distance
 */
@Injectable({ providedIn: 'root' })
export class MatrixService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDefaultUsingPost()` */
  static readonly GetDefaultUsingPostPath = '/v2/matrix/{profile}';

  /**
   * Matrix Service.
   *
   * Returns duration or distance matrix for mutliple source and destination points.
   * By default a symmetric duration matrix is returned where every point in locations is paired with each other. The result is null if a value can’t be determined.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDefaultUsingPost$JsonCharsetUtf8()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDefaultUsingPost$JsonCharsetUtf8$Response(params: GetDefaultUsingPost$JsonCharsetUtf8$Params, context?: HttpContext): Observable<StrictHttpResponse<JsonMatrixResponse>> {
    return getDefaultUsingPost$JsonCharsetUtf8(this.http, this.rootUrl, params, context);
  }

  /**
   * Matrix Service.
   *
   * Returns duration or distance matrix for mutliple source and destination points.
   * By default a symmetric duration matrix is returned where every point in locations is paired with each other. The result is null if a value can’t be determined.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDefaultUsingPost$JsonCharsetUtf8$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDefaultUsingPost$JsonCharsetUtf8(params: GetDefaultUsingPost$JsonCharsetUtf8$Params, context?: HttpContext): Observable<JsonMatrixResponse> {
    return this.getDefaultUsingPost$JsonCharsetUtf8$Response(params, context).pipe(
      map((r: StrictHttpResponse<JsonMatrixResponse>): JsonMatrixResponse => r.body)
    );
  }

  /**
   * Matrix Service.
   *
   * Returns duration or distance matrix for mutliple source and destination points.
   * By default a symmetric duration matrix is returned where every point in locations is paired with each other. The result is null if a value can’t be determined.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDefaultUsingPost$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDefaultUsingPost$Json$Response(params: GetDefaultUsingPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<JsonMatrixResponse>> {
    return getDefaultUsingPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Matrix Service.
   *
   * Returns duration or distance matrix for mutliple source and destination points.
   * By default a symmetric duration matrix is returned where every point in locations is paired with each other. The result is null if a value can’t be determined.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDefaultUsingPost$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDefaultUsingPost$Json(params: GetDefaultUsingPost$Json$Params, context?: HttpContext): Observable<JsonMatrixResponse> {
    return this.getDefaultUsingPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<JsonMatrixResponse>): JsonMatrixResponse => r.body)
    );
  }

}
